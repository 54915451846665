import React, { useState, useEffect, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { CartContext } from "../util/cartContext";
import { AgeVerificationContext } from "../util/AgeVerificationContext";
import { fetchProducts } from "../dataSource/ProductSource";
import "./styling/productPage.css";
import "./styling/productPage-s.css";
import menuLogo from "./assets/logoTrans.png";
import lemonSlice from "./assets/lemonSlice2.webp";
import lemonSlice1 from "./assets/lemonSlice.webp";
import branchRight1 from "./assets/branchRight1.webp";
import branchRight2 from "./assets/branchRight2.webp";
import branchLeftCorner from "./assets/branchLeftCorner.webp";
import branchLeft1 from "./assets/branchLeft1.webp";
import limoncello from "./assets/limoncello.jpg";
import valenti from "./assets/valenti.jpg";
import yuzu from "./assets/yuzu.jpg";
import valentiCoctail from "./assets/valentiCoctail.png";
import yuzuGin from "./assets/gin.jpg";
import cartImg from "./assets/cart.svg";

const Webshop = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

   const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  const { cart, addToCart, removeFromCart, totalPrice, cartMessage, cartMessageType } =
    useContext(CartContext);

  const { isOver18, setIsOver18 } = useContext(AgeVerificationContext);
  
  const [isMobile, setIsMobile] = useState(
    window.matchMedia("(max-width: 1200px)").matches
  );

  const [dynamicProducts, setDynamicProducts] = useState([]); 
  const [quantities, setQuantities] = useState({}); 
  const [feedbackMessages, setFeedbackMessages] = useState({}); 

  const routeMap = {
    1: "limoncello",
    2: "valenti",
    3: "yuzu",
    4: "yuzugin",
    5: "sanpellegrino"
  };

  useEffect(() => {
    const loadProducts = async () => {
      const data = await fetchProducts();
      setDynamicProducts(data);
    };
    loadProducts();
  }, []);

  const addProductToCart = (product) => {

    const quantity = quantities[product.id] || 1;
  
    // Prevent adding if stock is already in cart
    const cartItem = cart.find((item) => item.id === product.id);

    if (cartItem && cartItem.quantity >= (product.product_info?.stock ?? 20)) {      

      setFeedbackMessages((prev) => ({
        ...prev,
        [product.id]: "Maximaal aantal (" + (product.product_info?.stock !== null ? product.product_info.stock : 20) + ") bereikt."

      }));
      return;
    }
  
    addToCart(product, quantity);
  
    setFeedbackMessages((prev) => ({
      ...prev,
      [product.id]: "Item(s) toegevoegd aan mandje!",
    }));
  
    setTimeout(() => {
      setFeedbackMessages((prev) => ({ ...prev, [product.id]: "" }));
    }, 5000);
  };
  
  const handleQuantityChange = (productId, value) => {
    if (isNaN(value) || value < 1) return; 
  
    setQuantities((prevQuantities) => ({
      ...prevQuantities,
      [productId]: value, 
    }));
  };
  

  return (
    <>
      {!isOver18 && (
        <div id="ageWrapper">
          <div id="ageBox">

            <div id="ageLogoWrapper">
              <img src={menuLogo} alt=""></img>
            </div>

            <h1>Ben je 18 jaar of ouder?</h1>

            <div id="ageButtons">

              <button className="checkout-btn yes-btn" onClick={() => setIsOver18(true)}>Ja</button>
              <Link
                to="/"
              >
              <button className="checkout-btn">Nee</button>
              </Link>

            </div>
          </div>
        </div>
      )}
      {!isMobile && <div className="desktopNavSpacer"></div>}
      <div className="shopPromo">
      <div>
      <i class="bi bi-truck"></i>
      <strong>Gratis bezorging bij bestellingen vanaf €100</strong>
      </div>
      <div>
      <i class="bi bi-shop-window"></i>
      <strong>Bestel online & ophalen in de winkel (gratis)</strong>
      </div>
      </div>
      <div>
        <div id="productBox">
          <div id="productBoxWrapper">
            <img
              className="productBoxBackgroundImage"
              src={branchRight1}
              alt="background image"
            ></img>
            <img
              className="productBoxBackgroundImage"
              src={branchRight2}
              alt="background image"
            ></img>
            <img
              className="productBoxBackgroundImage"
              src={branchLeft1}
              alt="background image"
            ></img>
            <img
              className="productBoxBackgroundImage"
              src={branchLeftCorner}
              alt="background image"
            ></img>

            <img
              className="productBoxBackgroundImage"
              src={lemonSlice}
              alt="lemon slice"
            ></img>
            <img
              className="productBoxBackgroundImage"
              src={lemonSlice1}
              alt="lemon slice"
            ></img>

            <div className="productBoxTitle">
              <img src={menuLogo} alt="Pulcinos Logo"></img>

              <h1>Webshop</h1>
              {/* <span>Likeuren</span> */}
            </div>

            {dynamicProducts && (
            <div id="productContainer">
            
            {Object.values(dynamicProducts)
            .filter(product => product.product_info?.actief) 
              .map((product, index) => (
                <div data-aos="fade-up" className="product" key={product.id}>
                  <div className="productImageWrapper">
                    <img
                      src={product.images?.length > 0 ? product.images[0].image_url : "https://via.placeholder.com/150"}
                      alt={product.name}
                    />
                  </div>

                  <div className="productInfo">
                    {!isMobile ? (
                      <h2 className="productTitle">
                        <span className="yellow">{product.name}</span> - €{product.price.toFixed(2)}
                      </h2>
                    ) : (
                      <h2 className="productTitle">
                        <span className="yellow">{product.name}&nbsp;</span>
                        <span>€{product.price.toFixed(2)}</span>
                      </h2>
                    )}

                    <span className="productInfoSpan">{product.summary || "Geen beschrijving beschikbaar."}</span>

                    <div className="productButtons">
                    <Link
                      className="hightlightsLink"
                      to={`/webshop/${routeMap[product.id] || product.id}`}
                      style={{ textDecoration: "none" }}
                    >
                      {!isMobile ? <button>Bekijk product</button> : <button>Bekijk</button>}
                    </Link>

                      <input
                        type="number"
                        id="quantity"
                        value={quantities[product.id] || 1}
                        name={`quantity_${product.id}`}
                        max={product.product_info?.stock !== null ? product.product_info.stock : 20}
                        onChange={(e) => handleQuantityChange(product.id, parseInt(e.target.value, 10))}
                      />

                      <button className="add-to-cart" onClick={() => addProductToCart(product)}>
                        <img src={cartImg} alt="+" />
                      </button>
                    </div>

                    {feedbackMessages[product.id] && (
                      <div className="cart-feedback">{feedbackMessages[product.id]}</div>
                    )}
                  </div>
                </div>
              ))}
            
            </div>
            )}

            <div></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Webshop;
