// src/components/Analytics.js

import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import OrderAnalytics from './Analytics/OrderAnalytics';
import MenuAnalytics from './Analytics/MenuAnalytics';
import './styling/adminAnalytics.css';

const Analytics = () => {
 
  const [isMobile, setIsMobile] = useState(
    window.matchMedia("(max-width: 1200px)").matches
  );

  return (
    <>
      <div className="analytics-container">
        <h2 className='adminHeader'>Website Statestieken</h2>      
        <div className='analyticsBox'><OrderAnalytics/></div>  
        <div className='analyticsBox'><MenuAnalytics restaurant={"pulcinos"}/></div>
        <div className='analyticsBox'><MenuAnalytics restaurant={"satiata"}/></div>
      </div>
    </>
  );
};

export default Analytics;
