import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { fetchProducts, deleteProduct, updateProduct } from "../../dataSource/ProductSource";
import ConfirmModal from '../../components/ConfirmModal';
import './styling/adminProducts.css';

const AdminProducts = ({setActivePage}) => {
  const [products, setProducts] = useState([]);
  const [isMobile, setIsMobile] = useState(window.matchMedia("(max-width: 1200px)").matches);
  const [modal, setModal] = useState({ show: false, message: "", action: null });
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [editedStock, setEditedStock] = useState({});
  const [stockChanged, setStockChanged] = useState({});
  const [searchQuery, setSearchQuery] = useState("");
  const [filterStatus, setFilterStatus] = useState("all");
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });

   // Add sorting functionality
   const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  // Add sort indicators
  const getSortIndicator = (key) => {
    if (sortConfig.key !== key) return null;
    return sortConfig.direction === 'asc' ? ' ↑' : ' ↓';
  };

  // Add filtered and sorted products
  const filteredProducts = Object.values(products)
    .filter(product => {
      const matchesSearch = product.name.toLowerCase().includes(searchQuery.toLowerCase());
      const matchesStatus = filterStatus === 'all' || 
        (filterStatus === 'active' ? product.product_info?.actief : !product.product_info?.actief);
      return matchesSearch && matchesStatus;
    })
    .sort((a, b) => {
      if (!sortConfig.key) return 0;
      
      const aValue = sortConfig.key === 'price' ? parseFloat(a[sortConfig.key]) : a[sortConfig.key];
      const bValue = sortConfig.key === 'price' ? parseFloat(b[sortConfig.key]) : b[sortConfig.key];

      if (aValue < bValue) return sortConfig.direction === 'asc' ? -1 : 1;
      if (aValue > bValue) return sortConfig.direction === 'asc' ? 1 : -1;
      return 0;
    });
  

  useEffect(() => {
    loadProducts();
  }, []);

  const loadProducts = async () => {
    const data = await fetchProducts();
    console.log("found products: ", data);
    setProducts(data);
  };

  const handleDelete = (id) => {
    setModal({
      show: true,
      message: "Weet je zeker dat je dit product wilt verwijderen?",
      action: async () => {
        await deleteProduct(id);
        loadProducts();
        setModal({ show: false, message: "", action: null });
      },
    });
  };

  const handleToggleActive = (product) => {
    setModal({
      show: true,
      message: `Wil je het product "${product.name}" ${product.product_info?.actief ? "deactiveren" : "activeren"}?`,
      action: async () => {
        await updateProduct(product.id, { product_info: { actief: !product.product_info?.actief } });
        loadProducts();
        setModal({ show: false, message: "", action: null });
      },
    });
  };

  const handleStockSave = (product) => {
    const newStock = editedStock[product.id];
  
    setModal({
      show: true,
      message: `Wil je de voorraad van "${product.name}" aanpassen naar ${newStock === "" ? "∞" : newStock}?`,
      action: async () => {
        await updateProduct(product.id, JSON.stringify({
          product_info: { stock: newStock === "" ? null : parseInt(newStock, 10) }
        }));        
        loadProducts();
        setModal({ show: false, message: "", action: null });
        setStockChanged((prev) => ({ ...prev, [product.id]: false })); // ✅ Hide Save Button after saving
      },
    });
  };

  return (
    <>
      <div className="container containerProducts">
        <h2 className="adminHeader">Productbeheer</h2>

        <div className="admin-controls">

        <div className="lightBorder">

        <h3 className="adminHeader">Opties</h3>

            <button
            onClick={() => setActivePage("add-product")} 
            className="btn-add-product">
            <i class="bi bi-plus-circle"></i>
            Voeg product toe
            </button>

          </div>

          <div className="lightBorder filterBoxWrapper">

          <h3 className="adminHeader">Filters</h3>

          <div className="control-group ">

          <div id="orderSearchBar">
          <i className="bi bi-search"></i>
          <input
              type="text"
              placeholder="Zoek product..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="search-input"
            />
          </div>   
            
          <div className='filterWrapper lightBorder'>
          <label>
            <strong>Actief:</strong>
            <select 
              value={filterStatus} 
              onChange={(e) => setFilterStatus(e.target.value)}
              
            >
              <option value="all">Alle statussen</option>
              <option value="active">Alleen actieve</option>
              <option value="inactive">Alleen inactieve</option>
            </select>
          </label>   
          </div>          
          </div>   
          </div>     
     
        </div>

        <div className="lightBorder">
        <table className="product-table">
        <thead>
          <tr>
            {['name', 'price', 'stock', 'actief', 'acties'].map((header) => (
              <th 
                key={header}
                onClick={() => [ 'name', 'price', 'stock'].includes(header) ? handleSort(header) : null}
                className={['name', 'price', 'stock'].includes(header) ? 'sortable' : ''}
              >
                {header === 'actief' ? 'Actief' : header === 'acties' ? 'Acties' : header.charAt(0).toUpperCase() + header.slice(1)}
                {getSortIndicator(header)}
              </th>
            ))}
          </tr>
        </thead>
          <tbody>
            {filteredProducts.map((product) => (
              <tr key={product.id}>
                <td>{isMobile && (<strong>Naam: </strong>)}{product.name}</td>
                <td>{isMobile && (<strong>Prijs: </strong>)}€{(product.price).toFixed(2)}</td>
             
                <td>
                {isMobile && (
                  <>
                  <strong>Voorraad: </strong>
                    <button
                      onClick={() => handleStockSave(product)}
                      className="btn-download"
                      style={{
                        marginLeft: "10px",
                        visibility: stockChanged[product.id] ? 'visible' : 'hidden'
                      }}
                    >
                      <i className="bi bi-floppy2-fill"></i>
                    </button>
                    </>
                  )}
                  <input
                    type="number"
                    value={editedStock[product.id] ?? (product.product_info?.stock === null ? "" : product.product_info?.stock)}
                    placeholder="∞"
                    onChange={(e) => {
                      const value = e.target.value;
                      setEditedStock((prev) => ({ ...prev, [product.id]: value }));
                      setStockChanged((prev) => ({ ...prev, [product.id]: value !== String(product.product_info?.stock ?? "") }));
                    }}
                    style={{ width: "60px", textAlign: "center" }}
                  />

                  {!isMobile && (
                      <button
                        onClick={() => handleStockSave(product)}
                        className="btn-download"
                        style={{
                          marginLeft: "10px",
                          visibility: stockChanged[product.id] ? 'visible' : 'hidden'
                        }}
                      >
                        <i className="bi bi-floppy2-fill"></i>
                      </button>
                    )}
                </td>

                {!isMobile ? (
                  <>
                <td>
               
                    <button
                      onClick={() => handleToggleActive(product)}
                      className={product.product_info?.actief ? "btn-setActive btn-product-list btn-active-status" : "btn-delete btn-product-list btn-active-status"}
                    >
                      {product.product_info?.actief ? "Actief" : "Inactief"}
                    </button>
                  
                </td>
                <td className="product-option-buttons">
                    <button onClick={() => setActivePage(`edit-product-${product.id}`)}  className="btn-product-list btn-download"><i class="bi bi-pencil-square"></i></button>
                  <button className="btn-product-list btn-delete" onClick={() => handleDelete(product.id)}><i class="bi bi-trash"></i></button>
                </td>
                </>) : (
                    <td className="mobileCouponButtonsWrapper">               
                    <button
                      onClick={() => handleToggleActive(product)}
                      className={product.product_info?.actief ? "btn-setActive btn-product-list btn-active-status" : "btn-delete btn-product-list btn-active-status"}
                    >
                      {product.product_info?.actief ? "Actief" : "Inactief"}
                    </button>
                    <button onClick={() => setActivePage(`edit-product-${product.id}`)}  className="btn-product-list btn-download"><i class="bi bi-pencil-square"></i></button>
                  <button className="btn-product-list btn-delete" onClick={() => handleDelete(product.id)}><i class="bi bi-trash"></i></button>
                </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
        </div>
      </div>

      {/* ✅ Confirmation Modal */}
      <ConfirmModal
        show={modal.show}
        message={modal.message}
        onConfirm={modal.action}
        onCancel={() => setModal({ show: false, message: "", action: null })}
      />
    </>
  );
};

export default AdminProducts;
