import React, {useState, useEffect} from 'react';
import './styling/paymentMethodSelector.css'; // Assuming you're importing the CSS file here
import mastercard from "./assets/mastercard.svg";
import ideal from "./assets/ideal.svg";
import vpay from "./assets/vpay.png";
import meastro from "./assets/maestro.svg";
import visa from "./assets/visa.svg";

const PaymentMethodSelector = ({selectedMethod, setSelectedMethod }) => {

  const [paymentMethods, setPaymentMethods] = useState([
    { description: "iDEAL", id: "IDEAL", imageSource: ideal },
    { description: "MasterCard", id: "MASTERCARD", imageSource: mastercard },
    { description: "Visa", id: "VISA", imageSource: visa }, 
    { description: "Maestro", id: "MAESTRO", imageSource: meastro }, 
    { description: "V Pay", id: "V_PAY", imageSource: vpay } 
]);

 
  return (
    <div className="payment-method-selector">
      <h2>Selecteer Betaalmethode</h2>
      <div className="payment-method-list">
        {paymentMethods.map(method => (
          <div
            key={method.id}
            className={`payment-method-item ${selectedMethod === method.id ? 'selected' : ''}`}
            onClick={() => setSelectedMethod(method.id)}
          >
            <img src={method.imageSource} alt={method.id}></img>
            {method.description}
          </div>
        ))}
      </div>
    </div>
  );
};

export default PaymentMethodSelector;
