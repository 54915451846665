import React, { useState, useEffect } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { getAllOrders } from '../../../dataSource/OrderSource';

const OrderAnalytics = () => {
  const [data, setData] = useState([]);

   const [timeFrame, setTimeFrame] = useState('day');

  useEffect(() => {
    async function fetchData() {
      const result = await getAllOrders();
      if (result) {
        const aggregatedData = aggregateData(result, timeFrame);
        setData(aggregatedData);
      }
    }
    fetchData();
  }, [timeFrame]);

  const aggregateData = (orders, timeFrame) => {
    const now = new Date();
    const aggregatedData = orders.reduce((acc, order) => {
      const orderDate = new Date(order.order_date);
      let key;

      switch (timeFrame) {
        case 'year':
          key = orderDate.getFullYear();
          break;
        case 'month':
          if (orderDate.getFullYear() === now.getFullYear()) {
            key = orderDate.toLocaleString('default', { month: 'long' });
          }
          break;
        case 'week':
          if (orderDate.getFullYear() === now.getFullYear()) {
            const weekNumber = getWeekNumber(orderDate);
            key = `Week ${weekNumber}`;
          }
          break;
        case 'day':
          if (orderDate.getFullYear() === now.getFullYear() && 
              orderDate.getMonth() === now.getMonth()) {
            key = `${orderDate.getDate()} ${orderDate.toLocaleString('default', { month: 'long' })}`;
          }
          break;
        default:
          key = 'All Time';
      }

      if (key) {
        if (!acc[key]) {
          acc[key] = { name: key, orders: 0 };
        }
        acc[key].orders += 1;
      }

      return acc;
    }, {});

    return Object.values(aggregatedData);
  };

  const getWeekNumber = (date) => {
    const start = new Date(date.getFullYear(), 0, 1);
    const diff = (date - start + ((start.getTimezoneOffset() - date.getTimezoneOffset()) * 60000)) / 86400000;
    const weekNumber = Math.floor((diff + start.getDay() + 6) / 7);
    return weekNumber;
  };

  return (
    <div className="order-analytics lightBorder">

      <h2>Bestellingen Geplaatst</h2>
      <div className="filter-container lightBorder">
          <label htmlFor="timeFrame">Tijdframe:</label>
          <select id="timeFrame" value={timeFrame} onChange={(e) => setTimeFrame(e.target.value)}>
            <option value="all">Alles</option>
            <option value="year">Jaar</option>
            <option value="month">Maand</option>
            <option value="week">Week</option>
            <option value="day">Dag</option>
          </select>
        </div>

<ResponsiveContainer width="100%" height={400}>
      <BarChart
        width={800}
        height={400}
        data={data}
        margin={{
          top: 25, right: 0, left: 0, bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="orders" fill="#82ca9d" />
      </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default OrderAnalytics;
