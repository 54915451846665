import React, { useState, useEffect } from "react";
import { getOrderById } from "../dataSource/OrderSource";

const TestMail = () => {
    const [orderId, setOrderId] = useState(292);
    const [email, setEmail] = useState("");
    const [paymentMethod, setPaymentMethod] = useState("Credit Card");
    const [message, setMessage] = useState("");

    const [order, setOrder] = useState(null);

    const [isMobile, setIsMobile] = useState(
        window.matchMedia("(max-width: 1200px)").matches
      );

    useEffect(() => {
        async function fetchData() {    
          const result = await getOrderById(orderId);
          console.log(result);
          setOrder(result);            
        }
        fetchData();
      }, []);

    const sendTestMail = async () => {

        
        const order_details = {
            'orderPrice': order.price,
            'order_lines': order.order_lines
        }

        const response = await fetch("http://localhost:5009/send-mail-test", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                order_id: order.id,
                order_details: order_details,            
                to_email: 'prod.goldweb@gmail.com',
                payment_method: 'IDEAL',
                order: order
            }),
        });

        const data = await response.json();
        if (response.ok) {
            setMessage(data.message);
        } else {
            setMessage(data.error);
        }


        //console.log(order_details);
    };

    return (
        <>
        {!isMobile && (
            <div className="desktopNavSpacer"></div>
             )}
        <div style={{ padding: "20px", fontFamily: "Arial", display:"flex", justifyContent:"center", flexDirection:"column" }}>
            <h2 style={{ color: "white"}}>Test Order Confirmation Email</h2>
            
            <button
                onClick={sendTestMail}
                style={{
                    marginTop: "20px",
                    padding: "10px 15px",
                    backgroundColor: "#007bff",
                    color: "white",
                    border: "none",
                    cursor: "pointer",
                }}
            >
                Send Test Email
            </button>
            {message && <p style={{ marginTop: "20px", color: "green" }}>{message}</p>}
        </div>
        </>
    );
};

export default TestMail;
