import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useParams, useNavigate } from "react-router-dom";
import { fetchProductById, updateProduct } from "../../dataSource/ProductSource";
import logo from "../assets/logoWhite.png";
import Slider from "react-slick";

const AdminEditProduct = ({id, setActivePage}) => {

  const navigate = useNavigate();

  const [productType, setProductType] = useState("");
  const [product, setProduct] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    summary: "",
    price: "",
    stock: "",
    onder_titel: "",
    inhoud: "",
    percentage: "",
    temperatuur: "",
    smaak: "",
    lekker_bij: "",
    vinificatie: "",
    beschrijving: "",
    serveren: "",
    sluitzin: "",
    land: "",
    streek: "",
    wijnhuis: "",
    regio: "",
    druif: "",
    stijl: "",
  });

  const [isMobile, setIsMobile] = useState(
    window.matchMedia("(max-width: 1200px)").matches
  );

   const [nav1, setNav1] = useState(null);
      const [nav2, setNav2] = useState(null);

    
      const openPreview = () => {
        const previewWindow = window.open(
          "/admin/products/preview",
          "_blank"
        );
    
        if (previewWindow) {
          const previewData = {
            productData: { ...formData },
            productType: productType,
            selectedFiles: product.images.map((image) => ({
              id: image.id,
              url: image.image_url,
            })),
          };
    
          const interval = setInterval(() => {
            if (previewWindow && !previewWindow.closed) {
              previewWindow.postMessage({ checkReady: true }, "*");
            } else {
              clearInterval(interval);
            }
          }, 500);
    
          const listener = (event) => {
            if (event.data.ready) {
              clearInterval(interval);
              previewWindow.postMessage(previewData, "*");
              window.removeEventListener("message", listener);
            }
          };
    
          window.addEventListener("message", listener);
        } else {
          alert("Popup geblokkeerd! Sta pop-ups toe in je browser.");
        }
      };
    
  
    const settingsMain = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: false,
      lazyLoad: "ondemand", // Lazy load images on demand
      asNavFor: nav2, // Sync with thumbnail slider
      arrows: false,
    };
  
    const settingsThumbs = {
      slidesToShow: 2,
      slidesToScroll: 1,
      dots: false,
      centerMode: false,
      focusOnSelect: true,
      asNavFor: nav1, // Sync with main slider
      arrows: false,
      infinite: false,
    };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.matchMedia("(max-width: 1200px)").matches);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    async function loadProduct() {
      const data = await fetchProductById(id);
      if (data) {
        setProductType(data.product_info?.product_type || "");
        setProduct(data);
        setFormData({
          name: data.name || "",
          summary: data.summary || "",
          price: data.price || "",
          stock: data.product_info?.stock || "",
          onder_titel: data.product_info?.onder_titel || "",
          inhoud: data.product_info?.inhoud || "",
          percentage: data.product_info?.percentage || "",
          temperatuur: data.product_info?.temperatuur || "",
          smaak: data.product_info?.smaak || "",
          lekker_bij: data.product_info?.lekker_bij || "",
          vinificatie: data.product_info?.vinificatie || "",
          beschrijving: data.product_info?.beschrijving || "",
          serveren: data.product_info?.serveren || "",
          sluitzin: data.product_info?.sluitzin || "",
          land: data.product_info?.land || "",
          streek: data.product_info?.streek || "",
          wijnhuis: data.product_info?.wijnhuis || "",
          regio: data.product_info?.regio || "",
          druif: data.product_info?.druif || "",
          stijl: data.product_info?.stijl || "",
        });
      }
    }
    loadProduct();
  }, [id]);

  useEffect(() => {
    console.log("Product changed: ", product);
  }, [product]);

  // ✅ Handle input changes
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // ✅ Handle update submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    const updatedData = {
      name: formData.name,
      summary: formData.summary,
      price: parseFloat(formData.price),
      product_info: {
        product_type: productType,
        naam: formData.name,
        prijs: parseFloat(formData.price),
        stock: parseInt(formData.stock),
        onder_titel: formData.onder_titel,
        inhoud: formData.inhoud ? parseFloat(formData.inhoud) : null,
        percentage: formData.percentage ? parseFloat(formData.percentage) : null,
        temperatuur: formData.temperatuur || null,
        smaak: formData.smaak || null,
        lekker_bij: formData.lekker_bij || null,
        vinificatie: formData.vinificatie || null,
        beschrijving: formData.beschrijving,
        serveren: formData.serveren,
        sluitzin: formData.sluitzin,
        land: formData.land,
        streek: formData.streek || null,
        wijnhuis: formData.wijnhuis || null,
        regio: formData.regio || null,
        druif: formData.druif || null,
        stijl: formData.stijl || null,
      },
    };
    await updateProduct(id, updatedData);
    setActivePage("products");
  };

  return (
    <>
      <div className="productOverviewWrapper">
      <div className="container containerProducts">
        <h2 className="adminHeader">Bewerk Product</h2>
        <p>Bewerk hier je product, velden met * zijn verplicht</p>

        <form onSubmit={handleSubmit} className="product-form lightBorder">
          <label ><h4 className="adminHeader">* Naam:</h4></label>
          <input type="text" name="name" placeholder="Productnaam" value={formData.name} onChange={handleChange} required />
          <label ><h4 className="adminHeader">* Korte Beschrijving:</h4></label>
          <textarea name="summary" placeholder="Korte beschrijving (2 zinnen)" value={formData.summary} onChange={handleChange} required />
          <label ><h4 className="adminHeader">* Prijs:</h4></label>
          <input type="number" name="price" placeholder="Prijs (€)" value={formData.price} onChange={handleChange} required />
          <label ><h4 className="adminHeader">* Voorraad:</h4></label>
          <input type="number" name="stock" placeholder="Voorraad" value={formData.stock} onChange={handleChange} required />
          <label ><h4 className="adminHeader">* Ondertitel:</h4></label>
          <input type="text" name="onder_titel" placeholder="Ondertitel" value={formData.onder_titel} onChange={handleChange} />
          <label ><h4 className="adminHeader">* Volledige Beschrijving:</h4></label>
          <textarea name="beschrijving" placeholder="Uitgebreide beschrijving" value={formData.beschrijving} onChange={handleChange} required />
       
          <label ><h4 className="adminHeader">Sluitzin:</h4></label>
          <input type="text" name="sluitzin" placeholder="Sluitzin" value={formData.sluitzin} onChange={handleChange} />

          {/* ✅ Fields for Likeur & Wijn */}
          {(productType === "Likeur" || productType === "Wijn") && (
            <>
              <label ><h4 className="adminHeader">Hoe te serveren: </h4></label> 
              <p className="labelTip">geef meerdere items op door ze te scheiden met ***, voorbeeld: serveertip 1 *** serveertip 2 *** serveertip 3</p>
              <input type="text" name="serveren" placeholder="Hoe te serveren" value={formData.serveren} onChange={handleChange} />
              <label ><h4 className="adminHeader">Inhoud (in cl):</h4></label>
              <input type="number" name="inhoud" placeholder="Inhoud (cl)" value={formData.inhoud} onChange={handleChange} />
              <label ><h4 className="adminHeader">Alcohol percentage:</h4></label>
              <input type="number" name="percentage" placeholder="Alcoholpercentage (%)" value={formData.percentage} onChange={handleChange} />
              <label ><h4 className="adminHeader">Serveertemperatuur (in ˚C):</h4></label>
              <input type="text" name="temperatuur" placeholder="Serveertemperatuur" value={formData.temperatuur} onChange={handleChange} />
              <label ><h4 className="adminHeader">Land:</h4></label>
              <input type="text" name="land" placeholder="Land" value={formData.land} onChange={handleChange} />
              <label ><h4 className="adminHeader">Stijl:</h4></label>
              <input type="text" name="stijl" placeholder="Stijl" value={formData.stijl} onChange={handleChange} />
              <label ><h4 className="adminHeader">Smaakprofiel:</h4></label>
              <input type="text" name="smaak" placeholder="Smaakprofiel" value={formData.smaak} onChange={handleChange} />
            </>
          )}

          {/* ✅ Fields for Wijn Only */}
          {productType === "Wijn" && (
            <>
            
              <label ><h4 className="adminHeader">Lekker bij:</h4></label>
              <p className="labelTip">geef meerdere items op door ze te scheiden met ***, voorbeeld: serveertip 1 *** serveertip 2 *** serveertip 3</p>
              <input type="text" name="lekker_bij" placeholder="Lekker bij" value={formData.lekker_bij} onChange={handleChange} />
              <label ><h4 className="adminHeader">Vinificatie:</h4></label>
              <input type="text" name="vinificatie" placeholder="Vinificatie" value={formData.vinificatie} onChange={handleChange} />
              <label ><h4 className="adminHeader">Streek:</h4></label>
              <input type="text" name="streek" placeholder="Streek" value={formData.streek} onChange={handleChange} />
              <label ><h4 className="adminHeader">Wijnhuis:</h4></label>
              <input type="text" name="wijnhuis" placeholder="Wijnhuis" value={formData.wijnhuis} onChange={handleChange} />
              <label ><h4 className="adminHeader">Regio:</h4></label>
              <input type="text" name="regio" placeholder="Regio" value={formData.regio} onChange={handleChange} />
              <label ><h4 className="adminHeader">Druif:</h4></label>
              <input type="text" name="druif" placeholder="Druif" value={formData.druif} onChange={handleChange} />
             
            </>
          )}

          <button type="submit " className="btn-save-product">Opslaan</button>
        </form>
      </div>

      <div className="productPreviewButton" onClick={openPreview}>
      <i class="bi bi-eye-fill"></i>
      <span>Voorbeeld Bekijken</span>
       </div>

      </div>

     
    </>
  );
};

export default AdminEditProduct;
