import React, { useState, useEffect } from 'react';
import postnlLogo from './assets/postnl.svg';
import pulcinosLogo from '../pages/assets/logo.png'
import './styling/shippingOptions.css';
import leaf from './assets/leaf.svg';
import { fetchCheckoutOptions, fetchPickupOptions } from '../dataSource/PostNLSource';
import { ClipLoader } from 'react-spinners';

const ShippingOptionsBasic = ({ shippingOption, setShippingOption, orderPrice }) => {

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [isMobile, setIsMobile] = useState(
    window.matchMedia("(max-width: 1000px)").matches
  );

  const [expandedIndex, setExpandedIndex] = useState(null); // Track which opening times are expanded

  const [activeTab, setActiveTab] = useState('delivery');

  const toggleOpeningTimes = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index); // Toggle expansion
  };


  const translateDay = (day) => {
    switch (day.toLowerCase()) {
        case 'monday':
            return 'Maandag';
        case 'tuesday':
            return 'Dinsdag';
        case 'wednesday':
            return 'Woensdag';
        case 'thursday':
            return 'Donderdag';
        case 'friday':
            return 'Vrijdag';
        case 'saturday':
            return 'Zaterdag';
        case 'sunday':
            return 'Zondag';
        default:
            return day; // Return the original string if no match is found
    }
};

  const handleSelectOption = (option) => {
    setShippingOption(option);
  };

 

  if (error) {
    return <p>Error fetching shipping options: {error}</p>;
  }

  return (

    <div className="shipping-options">

    <img src={postnlLogo} alt='PostNL'></img>
   
        <>

        {loading ? (

          <div className='loaderDiv'>
        <ClipLoader color="#3498db" loading={loading} size={50} />
        </div>

        ) : (

      <div>

    <div id='tabsBox'>

    <span  className={`${activeTab === 'delivery' ? 'activeTab' : ''}`} 
          onClick={() => setActiveTab('delivery')} >Bezorgen</span>
    <span     className={`${activeTab === 'pickup' ? 'activeTab' : ''}`} 
          onClick={() => setActiveTab('pickup')}>Ophalen <img src={leaf} alt=''></img></span>

    </div>

    <div id='optionsWrapper'>    
      
    <div id='deliveryTab'>

    {activeTab === 'delivery' && (

      <ul> 
          <li key={"Delivery"}>
            <label>     
            <input
                type="radio"
                name="shippingOption"
                value={"Delivery"}  
                onChange={() => handleSelectOption("Delivery")}
                checked={shippingOption === "Delivery"}
            />         
              <div>
              {!isMobile ? (<p><strong>Bezorgd met PostNL</strong>&nbsp; - {orderPrice >= 100 ? ("( Gratis )") : ("( + 5.95 )")}</p>) : 
              (<>
              <p><strong>Bezorgd met PostNL</strong></p>
              <p>{orderPrice >= 100 ? ("( Gratis )") : ("( + 5.95 )")}</p>
              </>
              )}    
                            
              <p>                      
                Verzending in 3 tot 5 werkdagen
              </p>


                 
           
              </div>
            </label>
          </li>
      
      </ul>
    )}
      </div>

      <div id='pickupTab'>
      {activeTab === 'pickup' && (
      <ul>

      {/* Pickup in store */}
      <li key={"pickupli"}>
      <div className='pickupWrapper'>     
        <input
          type="radio"
          name="pickupOption"
          value={"winkel"}  // Use LocationCode as the value if available
          onChange={() => handleSelectOption("winkel")}
          checked={shippingOption === "winkel"}
        />         
        <div>

          {!isMobile ? (<p><strong>Afhalen in onze Winkel</strong> <img src={leaf} alt=''></img> - ( Gratis )</p>) : 
          (<><p><strong>Afhalen in onze Winkel</strong></p>
          <p>( Gratis )<img src={leaf} alt=''></img></p></>
          )}          

          <p>Heuvelstraat 131, 5038AD Tilburg</p>
          <a 
            href='https://www.google.com/maps/place/Satiata/@51.5556896,5.0809456,17.46z/data=!4m6!3m5!1s0x47c6bfd173488515:0xdfc428830d73fad2!8m2!3d51.5553374!4d5.0828985!16s%2Fg%2F11s2q85fwr?entry=ttu'
            target="_blank" 
            rel="noopener noreferrer"
            style={{ display: 'inline-block', marginTop: '10px', color: '#007BFF', textDecoration: 'none' }}
            >
            Toon op Kaart
            </a>
          
          <div className='openingWrapper' onClick={() => toggleOpeningTimes(-1)}>
                <p>Openingstijden:</p>
                <p>{expandedIndex === -1 ? '-' : '+'}</p>
              </div>
              
              <ul
                className='openingTimes'
                style={{
                  maxHeight: expandedIndex === -1 ? '2000px' : '0',
                  overflow: 'hidden',
                  transition: 'max-height 0.3s ease-in-out',
                }}
              >
                <li key={"Maandag"}>
                    <strong>Maandag:</strong> <p>12:00-19:30</p>
                </li>
                <li key={"Donderdag"}>
                    <strong>Donderdag:</strong> <p>12:00-18:00</p>
                </li>
                <li key={"Vrijdag"}>
                    <strong>Vrijdag:</strong> <p>12:00-17:00</p>
                </li>
                <li key={"Zaterdag"}>
                    <strong>Zaterdag:</strong> <p>12:30-17:00</p>
                </li>
                <li key={"Zondag"}>
                    <strong>Zondag:</strong> <p>13:00-17:00</p>
                </li>             
              </ul>                    
        </div>
      </div>
    </li>
 
</ul>
      )}
</div>

    </div>

    </div>

    )}
    </>
   
    </div>
    
  );
};

export default ShippingOptionsBasic;
