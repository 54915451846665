import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getAllCoupons, deleteCoupon, updateCoupon } from "../../dataSource/CouponSource";
import ConfirmModal from '../../components/ConfirmModal';
import './styling/adminProducts.css';

const AdminCoupons = ({setActivePage}) => {
  const [coupons, setCoupons] = useState([]);
  const [isMobile, setIsMobile] = useState(window.matchMedia("(max-width: 1200px)").matches);
  const [modal, setModal] = useState({ show: false, message: "", action: null });
  const [searchQuery, setSearchQuery] = useState("");
  const [filterStatus, setFilterStatus] = useState("all");
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });

  // Sorting logic for coupon table
  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const getSortIndicator = (key) => {
    if (sortConfig.key !== key) return null;
    return sortConfig.direction === 'asc' ? ' ↑' : ' ↓';
  };

  const filteredCoupons = coupons
    .filter(coupon => {
      const matchesSearch = coupon.code.toLowerCase().includes(searchQuery.toLowerCase());
      const matchesStatus = filterStatus === 'all' || coupon.status === filterStatus;
      return matchesSearch && matchesStatus;
    })
    .sort((a, b) => {
      if (!sortConfig.key) return 0;

      const aValue = a[sortConfig.key];
      const bValue = b[sortConfig.key];

      if (aValue < bValue) return sortConfig.direction === 'asc' ? -1 : 1;
      if (aValue > bValue) return sortConfig.direction === 'asc' ? 1 : -1;
      return 0;
    });

  useEffect(() => {
    loadCoupons();
  }, []);

  const loadCoupons = async () => {
    const data = await getAllCoupons();
    const validCoupons = data.filter(coupon => {
      const filteredCoupons = coupon.status !== 'disabled';
      return filteredCoupons;
    });
    setCoupons(validCoupons);
  };

  const handleDelete = (id) => {
    setModal({
      show: true,
      message: "Weet je zeker dat je deze coupon wilt verwijderen?",
      action: async () => {
        await deleteCoupon(id);
        loadCoupons();
        setModal({ show: false, message: "", action: null });
      },
    });
  };

  const handleToggleStatus = (coupon) => {
    setModal({
      show: true,
      message: `Wil je de coupon "${coupon.code}" ${coupon.status === 'active' ? "deactiveren" : "activeren"}?`,
      action: async () => {
        await updateCoupon(coupon.id, { status: coupon.status === 'active' ? 'inactive' : 'active' });
        loadCoupons();
        setModal({ show: false, message: "", action: null });
      },
    });
  };

  return (
    <>
      <div className="productOverviewWrapper">
        <div className="container containerProducts">
          <h2 className="adminHeader">Couponbeheer</h2>


        <div className="admin-controls">
        
                <div className="lightBorder">
        
                <h3 className="adminHeader">Opties</h3>
                      <button 
                      onClick={() => setActivePage("add-coupon")} 
                      className="btn-add-product">
                      <i class="bi bi-plus-circle"></i>
                        Voeg coupon toe
                      </button>
        
            </div>


          <div className="lightBorder filterBoxWrapper">

            <h3 className="adminHeader">Filters</h3>

            <div className="control-group ">

            <div id="orderSearchBar">
            <i className="bi bi-search"></i>
            <input
                type="text"
                placeholder="Zoek coupon..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="search-input"
              />
            </div>   
              
            <div className='filterWrapper lightBorder'>
            <label>
              <strong>Actief:</strong>
              <select 
                value={filterStatus} 
                onChange={(e) => setFilterStatus(e.target.value)}
                className="status-filter"
              >
                <option value="all">Alle statussen</option>
                <option value="active">Alleen actieve</option>
                <option value="inactive">Alleen inactieve</option>
              </select>
            </label>   
            </div>          
            </div>   
            </div>  

          </div>
       

          <div className="lightBorder">

           {filteredCoupons.length > 0 ? (
          <table className="product-table coupon-table">
            <thead>
              <tr>
                {['code', 'Kortings type', 'Waarde', 'status', 'acties'].map((header) => (
                  <th 
                    key={header}
                    onClick={() => ['code', 'Kortings type', 'Waarde'].includes(header) ? handleSort(header) : null}
                    className={[ 'code', 'discount_type', 'discount_value'].includes(header) ? 'sortable' : ''}
                  >
                    {header === 'status' ? 'Status' : header.charAt(0).toUpperCase() + header.slice(1)}
                    {getSortIndicator(header)}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {filteredCoupons.map((coupon) => (
                <tr key={coupon.id}>
                  <td>{isMobile && (<strong>Code: </strong>)}{coupon.code}</td>
                  <td>{isMobile && (<strong>Type: </strong>)}{coupon.discount_type}</td>
                  <td>{isMobile && (<strong>Waarde: </strong>)}{coupon.discount_type == "percentage" ? ("%") : ("€")}&nbsp;{coupon.discount_value}</td>
                  {!isMobile ? (
                  <>
                  <td>
                        <button
                          onClick={() => handleToggleStatus(coupon)}
                          className={coupon.status === 'active' ? "btn-setActive btn-product-list btn-active-status-coupon" : "btn-delete btn-product-list btn-active-status-coupon"}
                        >
                          {coupon.status === 'active' ? "Actief" : "Inactief"}
                        </button>
                   
                  </td>
                  <td className="product-option-buttons">
                      <button   onClick={() => setActivePage(`edit-coupon-${coupon.id}`)}  className="btn-product-list btn-download"><i class="bi bi-pencil-square"></i></button>
                   <button className="btn-product-list btn-delete" onClick={() => handleDelete(coupon.id)}><i class="bi bi-trash"></i></button>
                  </td>
                  </>
                  ):(
                    <td className="mobileCouponButtonsWrapper">
                    <button
                      onClick={() => handleToggleStatus(coupon)}
                      className={coupon.status === 'active' ? "btn-setActive btn-product-list btn-active-status-coupon" : "btn-delete btn-product-list btn-active-status-coupon"}
                    >
                      {coupon.status === 'active' ? "Actief" : "Inactief"}
                    </button>
                    <button   onClick={() => setActivePage(`edit-coupon-${coupon.id}`)}  className="btn-product-list btn-download"><i class="bi bi-pencil-square"></i></button>
                   <button className="btn-product-list btn-delete" onClick={() => handleDelete(coupon.id)}><i class="bi bi-trash"></i></button>
                                     
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
           ) : (
            <span>Nog geen coupons gevonden.</span>
           )}
          </div>
        </div>

        <ConfirmModal
          show={modal.show}
          message={modal.message}
          onConfirm={modal.action}
          onCancel={() => setModal({ show: false, message: "", action: null })}
        />
      </div>
    </>
  );
};

export default AdminCoupons;
