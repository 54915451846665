import React, { useState, useEffect } from "react";
import { fetchUsers, updatePassword } from "../../dataSource/UserSource";
import ConfirmModal from '../../components/ConfirmModal';
import './styling/adminProducts.css'; // ✅ Uses the same styling

const AdminUsers = ({ setActivePage }) => {
  const [users, setUsers] = useState([]);
  const [isMobile, setIsMobile] = useState(window.matchMedia("(max-width: 1200px)").matches);
  const [modal, setModal] = useState({ show: false, message: "", action: null });
  const [searchQuery, setSearchQuery] = useState("");
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });

  // ✅ State for password modal
  const [passwordModal, setPasswordModal] = useState({ show: false, userId: null, username: "", newPassword: "", confirmPassword: "", error: "" });

  useEffect(() => {
    loadUsers();
  }, []);

  const loadUsers = async () => {
    const data = await fetchUsers();
    setUsers(data);
  };

  // ✅ Sorting function
  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  // ✅ Show sort indicator
  const getSortIndicator = (key) => {
    if (sortConfig.key !== key) return null;
    return sortConfig.direction === 'asc' ? ' ↑' : ' ↓';
  };

  // ✅ Apply search and sorting
  const filteredUsers = Object.values(users)
    .filter(user => user.username.toLowerCase().includes(searchQuery.toLowerCase()) || 
                     user.email.toLowerCase().includes(searchQuery.toLowerCase()))
    .sort((a, b) => {
      if (!sortConfig.key) return 0;
      const aValue = a[sortConfig.key]?.toLowerCase();
      const bValue = b[sortConfig.key]?.toLowerCase();
      return sortConfig.direction === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
    });

  // ✅ Open the password modal
  const openPasswordModal = (user) => {
    setPasswordModal({ show: true, userId: user.id, username: user.username, newPassword: "", confirmPassword: "", error: "" });
  };

  // ✅ Handle password change
  const handlePasswordChange = (e) => {
    setPasswordModal(prev => ({ ...prev, newPassword: e.target.value }));
  };

  // ✅ Handle confirm password change
  const handleConfirmPasswordChange = (e) => {
    setPasswordModal(prev => ({ ...prev, confirmPassword: e.target.value }));
  };

  // ✅ Validate and update password
  const handlePasswordSave = async () => {
    const { userId, newPassword, confirmPassword } = passwordModal;

    // Check if passwords match
    if (newPassword !== confirmPassword) {
      setPasswordModal(prev => ({ ...prev, error: "Wachtwoorden komen niet overeen." }));
      return;
    }

    // Validate password strength
    if (
      newPassword.length < 14 ||
      !/[A-Z]/.test(newPassword) ||
      !/[a-z]/.test(newPassword) ||
      !/[0-9]/.test(newPassword) ||
      !/[!@#$%^&*(),.?":{}|<>]/.test(newPassword)
    ) {
      setPasswordModal(prev => ({ ...prev, error: "Wachtwoord moet 14 tekens, hoofdletter, kleine letter, nummer en speciaal teken bevatten." }));
      return;
    }

    // Show confirmation modal before saving
    setModal({
      show: true,
      message: `Wil je het wachtwoord voor "${passwordModal.username}" wijzigen?`,
      action: async () => {
        await updatePassword(userId, newPassword);
        setPasswordModal({ show: false, userId: null, username: "", newPassword: "", confirmPassword: "", error: "" });
        setModal({ show: false, message: "", action: null });
      },
    });
  };

  return (
    <>
      <div className="container containerProducts">
        <h2 className="adminHeader">Gebruikersbeheer</h2>

        <div className="admin-controls">

        <div className="lightBorder">

        <h3 className="adminHeader">Opties</h3>

            <button
            onClick={() => setActivePage("add-user")} 
            className="btn-add-product">
            <i class="bi bi-plus-circle"></i>
            Voeg gebruiker toe
            </button>

          </div>

          <div className="lightBorder filterBoxWrapper">

          <h3 className="adminHeader">Filters</h3>

          <div className="control-group ">

          <div id="orderSearchBar">
              <i className="bi bi-search"></i>
              <input
                type="text"
                placeholder="Zoek gebruiker..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="search-input"
              />
            </div>
                 
          </div>   
          </div>     
     
        </div>

        <div className="lightBorder">
          <table className="product-table user-table">
            <thead>
              <tr>
                {["username", "email", "role", "acties"].map((header) => (
                  <th 
                    key={header}
                    onClick={() => ["username", "email", "role"].includes(header) ? handleSort(header) : null}
                    className={["username", "email", "role"].includes(header) ? "sortable" : ""}
                  >
                    {header === "acties" ? "Acties" : header.charAt(0).toUpperCase() + header.slice(1)}
                    {getSortIndicator(header)}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {filteredUsers.map((user) => (
                <tr key={user.id}>
                  <td>{isMobile && (<strong>Gebruikersnaam: </strong>)}{user.username}</td>
                  <td>{isMobile && (<strong>Email: </strong>)}{user.email}</td>
                  <td>{isMobile && (<strong>Rol: </strong>)}{user.role}</td>
                  <td>
                    { user.id != 1 && (
                    <button className="btn-download" onClick={() => openPasswordModal(user)}>
                      <i className="bi bi-key-fill"></i> Wachtwoord wijzigen
                    </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* ✅ Password Modal */}
      {passwordModal.show && (
            <div className="modal-overlay">
      <div className="modal">
            <h3>Wijzig wachtwoord voor {passwordModal.username}</h3>
            <input
              type="password"
              placeholder="Nieuw wachtwoord"
              value={passwordModal.newPassword}
              onChange={handlePasswordChange}
              className="form-control"
            />
            <input
              type="password"
              placeholder="Bevestig wachtwoord"
              value={passwordModal.confirmPassword}
              onChange={handleConfirmPasswordChange}
              className="form-control"
            />
            {passwordModal.error && <p className="error-text">{passwordModal.error}</p>}

            <div className="modal-buttons">
              <button className="btn btn-pulci-green" onClick={handlePasswordSave}>
                Opslaan
              </button>
              <button className="btn btn-cancel" onClick={() => setPasswordModal({ show: false, userId: null, username: "", newPassword: "", confirmPassword: "", error: "" })}>
                Annuleren
              </button>
            </div>
          </div>
        </div>
      )}

      {/* ✅ Confirmation Modal */}
      <ConfirmModal
        show={modal.show}
        message={modal.message}
        onConfirm={modal.action}
        onCancel={() => setModal({ show: false, message: "", action: null })}
      />
    </>
  );
};

export default AdminUsers;
