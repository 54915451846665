import React from "react";
import { Link } from "react-router-dom";

const OrderTable = ({ setActivePage, orders, isMobile, expandedOrders, toggleOrderExpand }) => {
  return (
    <table>
      <thead>
        <tr>
          <th>ID</th>
          {isMobile ? (
            <th>Acties</th>
          ) : (
            <>
              <th>Prijs</th>
              <th>Klant Email</th>
              <th>Stad</th>
              <th>Besteldatum</th>
              <th>Levering</th>
              <th>Status</th>
              <th>Acties</th>
            </>
          )}
        </tr>
      </thead>
      <tbody>
        {orders.map((order) => (
          <React.Fragment key={order.id}>
            {/* ✅ Main Row */}
            <tr>
              <td><strong>ID:</strong>&nbsp;{order.id}</td>
              {isMobile ? (
                <td>
                  <button className="btn-download" onClick={() => toggleOrderExpand(order.id)}>
                    {expandedOrders[order.id] ? "Verberg" : "Uitvouwen"}
                  </button>
                    <button onClick={() => setActivePage(`order-details-${order.id}`)} className="btn-download">Details Bekijken</button>
                 
                </td>
              ) : (
                <>
                  <td>€{order.price}</td>
                  <td>{order.customer.email_address}</td>
                  <td>{order.address.city}</td>
                  <td>{order.order_date}</td>
                  <td>{order.shipping_data?.delivery_options}</td>
                  <td>{order.status}</td>
                  <td>
                      <button onClick={() => setActivePage(`order-details-${order.id}`)} className="btn-download">Details Bekijken</button>
                  </td>
                </>
              )}
            </tr>

            {/* ✅ Expanded Content in Mobile View */}
            {isMobile && expandedOrders[order.id] && (
              <tr>
                <td colSpan="2">
                  <div className="expanded-content">
                    <p><strong>Prijs:</strong> €{order.price}</p>
                    <p><strong>Klant Email:</strong> {order.customer.email_address}</p>
                    <p><strong>Stad:</strong> {order.address.city}</p>
                    <p><strong>Besteldatum:</strong> {order.order_date}</p>
                    <p><strong>Levering:</strong> {order.shipping_data?.delivery_options}</p>
                    <p><strong>Status:</strong> {order.status}</p>
                  </div>
                </td>
              </tr>
            )}
          </React.Fragment>
        ))}
      </tbody>
    </table>
  );
};

export default OrderTable;
