import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom"; 
import ReactGA from 'react-ga4';
import CartProvider from "./util/cartContext";
import CookieBanner from "./util/CookieBanner";
import Cart from "./components/cart";
import Home from "./pages/Home";
import Admin from "./pages/admin/Admin";
import AdminOrderDetails from "./pages/admin/AdminOrderDetails";
import AdminOrders from "./pages/admin/AdminOrders";
import About from "./pages/About";
import SatiataRestaurant from "./pages/SatiataRestaurant";
import SatiataStore from "./pages/SatiataStore";
import Liquors from "./pages/Liquors";
import Contact from "./pages/Contact";
import Nav from "./components/Nav";
import Login from "./pages/Login";
import Register from "./pages/register";
import AOS from "aos";
import "aos/dist/aos.css";
import ProtectedRoute from "./util/protectedRoute";
import SetMenu from "./pages/admin/SetMenu";
import Footer from "./components/Footer";
import Webshop from "./pages/Webshop";
import Limoncello from "./pages/product pages/Limoncello";
import OrderDetails from "./components/OrderDetails";
import AdminAnalytics from "./pages/admin/AdminAnalytics";
import ThankYou from "./pages/ThankYou";
import AdminShipping from "./pages/admin/AdminShipping";
import Restraunt from "./pages/Restraunt";
import Valenti from "./pages/product pages/Valenti";
import Gin from "./pages/product pages/Gin";
import Yuzu from "./pages/product pages/Yuzu";
import SanPellegrino from "./pages/product pages/SanPellegrino";
import { NavProvider } from "./util/navContext";
import { AgeVerificationProvider } from "./util/AgeVerificationContext";
import Cocktails from "./pages/Cocktails";
import AdminProducts from "./pages/admin/AdminProducts";
import AdminAddProduct from "./pages/admin/AdminAddProduct";
import AdminEditProduct from "./pages/admin/AdminEditProduct";
import ProductPage from "./pages/product pages/ProductPage";
import RetourBeleid from "./pages/RetourBeleid";
import PrivacyBeleid from "./pages/PrivacyBeleid";
import AdminCoupons from "./pages/admin/AdminCoupons";
import AddCoupon from "./pages/admin/AdminAddCoupon";
import AdminEditCoupon from "./pages/admin/AdminEditCoupon";
import TestMail from "./util/TestMail";
import ProductPreview from "./pages/admin/AdminProductPreview";
import ResetPassword from "./pages/ResetPassword";
import ForgotPassword from "./pages/ForgotPassword";

function App() {
  const location = useLocation(); // Use useLocation to get the current route
  const [isMobile, setIsMobile] = useState(
    window.matchMedia("(max-width: 1200px)").matches
  );

  const [showNav, setShowNav] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem('cookieConsent');
    if (consent === 'accepted') {
      ReactGA.initialize('G-W9GL2EQ8EG');
      const pagePath = location.pathname + location.search; 
      ReactGA.send({ hitType: 'pageview', page: pagePath, title: pagePath });
    }
  }, [location]);


  useEffect(() => {
    AOS.init({
      duration: 250, // Animation duration
      easing: "ease-out", // Easing function
      once: true, // Whether animation should happen only once
    });
  }, []);

  return (
    <AgeVerificationProvider>
      <CartProvider>
        <NavProvider>
          <div className="App">
            {location.pathname !== "/" && (location.pathname !== "/admin" || !isMobile) ? <Nav /> : showNav && <Nav />}
            <CookieBanner />

            <Routes>
              {/* <Route
                path="/"
                element={
                  <ProtectedRoute>
                    <Home onLoadComplete={() => setShowNav(true)} />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/about"
                element={
                  <ProtectedRoute>
                    <About />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/restaurant"
                element={
                  <ProtectedRoute>
                    <Restraunt />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/satiata/restaurant"
                element={
                  <ProtectedRoute>
                    <SatiataRestaurant />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/satiata/store"
                element={
                  <ProtectedRoute>
                    <SatiataStore />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/liquors"
                element={
                  <ProtectedRoute>
                    <Liquors />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/cocktails"
                element={
                  <ProtectedRoute>
                    <Cocktails />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/contact"
                element={
                  <ProtectedRoute>
                    <Contact />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/webshop"
                element={
                  <ProtectedRoute>
                    <Webshop />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/webshop/limoncello"
                element={
                  <ProtectedRoute>
                    <Limoncello />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/webshop/valenti"
                element={
                  <ProtectedRoute>
                    <Valenti />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/webshop/yuzugin"
                element={
                  <ProtectedRoute>
                    <Gin />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/webshop/yuzu"
                element={
                  <ProtectedRoute>
                    <Yuzu />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/webshop/sanpellegrino"
                element={
                  <ProtectedRoute>
                    <SanPellegrino />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/cart"
                element={
                  <ProtectedRoute>
                    <Cart />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/orderdetails"
                element={
                  <ProtectedRoute>
                    <OrderDetails />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/thankyou"
                element={
                  <ProtectedRoute>
                    <ThankYou />
                  </ProtectedRoute>
                }
              /> */}


              <Route
                path="/"
                element={
                    <Home onLoadComplete={() => setShowNav(true)} />
                }
              />

              <Route
                path="/about"
                element={
                  <About />
                }
              />
              <Route
                path="/restaurant"
                element={
                    <Restraunt />
                }
              />

              <Route
                path="/satiata/restaurant"
                element={
                    <SatiataRestaurant />
                }
              />

              <Route
                path="/satiata/store"
                element={
                    <SatiataStore />
                }
              />

              <Route
                path="/liquors"
                element={
                    <Liquors />
                }
              />

              {/* <Route
                path="/cocktails"
                element={
                  <ProtectedRoute>
                    <Cocktails />
                  </ProtectedRoute>
                }
              /> */}

              <Route
                path="/contact"
                element={
                    <Contact />
                }
              />

              <Route
                path="/webshop"
                element={
                    <Webshop />
                }
              />
              <Route
                path="/webshop/limoncello"
                element={
                    <Limoncello />
                }
              />
              <Route
                path="/webshop/valenti"
                element={
                    <Valenti />
                }
              />
              <Route
                path="/webshop/yuzugin"
                element={
                    <Gin />
                }
              />
              <Route
                path="/webshop/yuzu"
                element={
                    <Yuzu />
                }
              />
              <Route
                path="/webshop/:id"
                element={
                    <ProductPage />
                }
              />
              <Route
                path="/webshop/sanpellegrino"
                element={
                    <SanPellegrino />
                }
              />

              <Route
                path="/cart"
                element={
                    <Cart />
                }
              />
              <Route
                path="/orderdetails"
                element={
                    <OrderDetails />
                }
              />
              <Route
                path="/thankyou"
                element={
                    <ThankYou />
                }
              />
              <Route
                path="/retourbeleid"
                element={
                    <RetourBeleid />
                }
              />
               <Route
                path="/privacybeleid"
                element={
                    <PrivacyBeleid />
                }
              />



              {/* Login Route (Public) */}
              <Route path="/login" element={<Login />} />

              <Route
                path="/admin"
                element={
                  <ProtectedRoute>
                    <Admin />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/admin/setmenu/:restaurant"
                element={
                  <ProtectedRoute>
                    <SetMenu />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/admin/orders"
                element={
                  <ProtectedRoute>
                    <AdminOrders />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/admin/shipping"
                element={
                  <ProtectedRoute>
                    <AdminShipping />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/admin/order/:id/:from"
                element={
                  <ProtectedRoute>
                    <AdminOrderDetails />
                  </ProtectedRoute>
                }
              />
              <Route
            path="/admin/products"
            element={
              <ProtectedRoute>
                <AdminProducts />
              </ProtectedRoute>
            }/>
            <Route
            path="/admin/products/preview"
            element={
              <ProtectedRoute>
                <ProductPreview />
              </ProtectedRoute>
            }
          />
           <Route
            path="/admin/products/add"
            element={
              <ProtectedRoute>
                <AdminAddProduct />
              </ProtectedRoute>
            }
          />
            <Route
            path="/admin/products/edit/:id"
            element={
              <ProtectedRoute>
                <AdminEditProduct />
              </ProtectedRoute>
            }
          />
           <Route
                path="/admin/coupons"
                element={
                  <ProtectedRoute>
                    <AdminCoupons />
                  </ProtectedRoute>
                }
              />
            <Route
            path="/admin/coupons/add"
            element={
              <ProtectedRoute>
                <AddCoupon />
              </ProtectedRoute>
            }
          />
            <Route
            path="/admin/coupons/edit/:id"
            element={
              <ProtectedRoute>
                <AdminEditCoupon />
              </ProtectedRoute>
            }
          />
              <Route
                path="/admin/analytics"
                element={
                  <ProtectedRoute>
                    <AdminAnalytics />
                  </ProtectedRoute>
                }
              />
               <Route
            path="/admin/mail/test"
            element={
              <ProtectedRoute>
                <TestMail/>
              </ProtectedRoute>
            }
          />
              <Route
                path="/register"
                element={
                  <ProtectedRoute>
                    <Register />
                  </ProtectedRoute>
                }
              />
            <Route
                path="/user/forgot"
                element={
                    <ForgotPassword />
                }
              />
            <Route
                path="/user/reset/:token"
                element={
                    <ResetPassword />
                }
              />
            </Routes>
            {location.pathname !== "/" && (location.pathname !== "/admin" || !isMobile) ? <Footer /> : null}
            </div>
        </NavProvider>
      </CartProvider>
    </AgeVerificationProvider>
  );
}

export default App;
