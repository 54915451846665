const BASE_URL = process.env.REACT_APP_BASE_URL;
const maxRetries = 5;
const retryDelay = 1000;

async function fetchWithRetry(url, options = {}, retries = maxRetries) {
  for (let attempt = 1; attempt <= retries; attempt++) {
    try {
      const response = await fetch(url, options);
      if (response.ok) return response; // Success
      console.error(`Attempt ${attempt} failed: ${response.statusText}`);
    } catch (error) {
      console.error(`Attempt ${attempt} failed with error:`, error);
      if (attempt === retries) throw error; // Re-throw if final attempt
    }
    // Wait before next attempt
    await new Promise(resolve => setTimeout(resolve, retryDelay));
  }
}

// Fetch an order by ID
export async function getOrderById(id) {
  const token = localStorage.getItem("Token");

  try {
    const response = await fetch(`${BASE_URL}/order/${id}`, {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${token}`, 
      },
    });

    if (response.ok) {
      const order = await response.json();
      return {
        ...order,
        customer: order.customer,
        address: order.address,
        order_lines: order.order_lines,
      };
    } else {
      console.error("Failed to fetch order details");
      return false;
    }
  } catch (error) {
    console.error("Error fetching order details:", error);
    return false;
  }
}


// Fetch all orders
export async function getAllOrders() {
  const token = localStorage.getItem("Token");

  try {
    const response = await fetch(`${BASE_URL}/orders`, {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${token}`, 
      },
    });

    if (response.ok) {
      const orders = await response.json();
      return orders.map(order => ({
        ...order,
        customer: {
          email_address: order.customer.email_address,
        },
        address: {
          city: order.address.city,
        },
      }));
    } else {
      console.error("Failed to fetch orders");
      return false;
    }
  } catch (error) {
    console.error("Error fetching orders:", error);
    return false;
  }
}


export async function addOrder(orderPrice, note, customerData, addressData, shippingAddressData, shippingData, orderLines, method, redirectUrl, coupon, couponDiscount) {
  try {
    console.log("Sending ", coupon, couponDiscount);
     const response = await fetchWithRetry(`${BASE_URL}/order`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ orderPrice, note, customerData, addressData, shippingAddressData, shippingData, orderLines, method, redirectUrl, coupon, couponDiscount })
    });
    
    if (response) {
      const newOrder = await response.json();
      console.log(newOrder);
      return newOrder;
    } else {
      console.error('Failed to add order after retries');
      return false;
    }
  } catch (error) {
    console.error('Error adding order:', error);
    return false;
  }
}



// Update an order by ID
export async function updateOrder(orderId, data) {
  const token = localStorage.getItem("Token"); 

  try {
    const response = await fetch(`${BASE_URL}/order/${orderId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });

    return response.ok;
  } catch (error) {
    console.error("Error updating order:", error);
    return false;
  }
}


// Download order PDF
export async function downloadOrderPdf(orderId) {
  const token = localStorage.getItem("Token");

  try {
    const response = await fetch(`${BASE_URL}/order/${orderId}/pdf`, {
      method: "GET",
      headers: {
        "Content-Type": "application/pdf",
        "Authorization": `Bearer ${token}`,
      },
    });

    if (response.ok) {
      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `order_${orderId}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      return true;
    } else {
      console.error("Failed to download order PDF");
      return false;
    }
  } catch (error) {
    console.error("Error downloading order PDF:", error);
    return false;
  }
}
