import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import './styling/retour.css';
import logo from './assets/logo.png';

function PrivacyBeleid() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [isMobile, setIsMobile] = useState(
    window.matchMedia("(max-width: 1200px)").matches
  );

  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  return (
    <>
      {!isMobile && (
        <div className="desktopNavSpacer"></div>
      )}

      <div id="retourBox">
        <div className="retourWrapper">
          <div id="contactTitleWrapper">
            <h1>Privacybeleid – Pulcinos Liquor Co.</h1>
          </div>

          <span>Bij Pulcinos Liquor Co. hechten we veel waarde aan de bescherming van je persoonsgegevens. In dit privacybeleid leggen we uit hoe wij je gegevens verzamelen, gebruiken, en beschermen.</span>

          <div className="wieWrapper">
          <span ><strong>Wie zijn wij?</strong></span>
          <p><strong>Naam:</strong> Pulcinos Liquor Co.</p>
          <p><strong>E-mailadres:</strong> <a href="mailto:contact@pulcinos.nl">contact@pulcinos.nl</a></p>
        </div>

          <div>
            <span><strong>Welke gegevens verzamelen wij?</strong></span>
            <ul>
              <li>Persoonlijke identificatiegegevens (zoals naam, adres, e-mailadres, telefoonnummer).</li>
              <li>Betalingsgegevens (bijvoorbeeld via iDEAL, creditcard of PayPal).</li>
              <li>Transactie-informatie (bestellingen, facturen, leveringsinformatie).</li>
              <li>Gebruik van onze website, met inbegrip van gegevens die lokaal worden opgeslagen in je browser voor het winkelwagentje.</li>
            </ul>
          </div>

          <div>
            <span><strong>Waarom verzamelen wij deze gegevens?</strong></span>
            <ul>
              <li>Om bestellingen te verwerken en producten te leveren.</li>
              <li>Om betalingen te verwerken en fraude te voorkomen.</li>
              <li>Om de werking van de website te verbeteren en de gebruikerservaring te analyseren.</li>
            </ul>
          </div>

          <div>
            <span><strong>Hoe bewaren wij je gegevens?</strong></span>
            <ul>
              <li>Wij bewaren je gegevens zo lang als nodig is om de genoemde doelen te vervullen.</li>
              <li>Als je gegevens niet langer nodig zijn, worden ze verwijderd uit ons systeem.</li>
            </ul>
          </div>

          <div>
            <span><strong>Je rechten met betrekking tot je gegevens</strong></span>
            <ul>
              <li>Je hebt het recht om je gegevens in te zien, te corrigeren, te verwijderen of te beperken.</li>
              <li>Je kunt bezwaar maken tegen de verwerking van je gegevens of je toestemming intrekken.</li>
              <li>Je kunt een klacht indienen bij de Autoriteit Persoonsgegevens als je denkt dat we je gegevens niet correct behandelen.</li>
            </ul>
          </div>

          <div>
          <span><strong>Cookies</strong></span>
          <ul>
            <li>We gebruiken cookies voor het verzamelen van gegevens via Google Analytics om je ervaring te verbeteren.</li>
            <li>Daarnaast slaan we gegevens lokaal op, zoals je winkelwagentje en leeftijdsbevestiging, om je voorkeuren en instellingen te onthouden.</li>
            <li>Je kunt je gegevens in je browserinstellingen beheren. We delen deze gegevens niet met derden.</li>
          </ul>
        </div>

          <div>
            <span><strong>Beveiliging van je gegevens</strong></span>
            <ul>
              <li>We nemen passende beveiligingsmaatregelen om je gegevens te beschermen tegen verlies, misbruik en ongeautoriseerde toegang.</li>
            </ul>
          </div>

          <div>
            <span><strong>Wijzigingen in dit privacybeleid</strong></span>
            <ul>
              <li>We kunnen dit privacybeleid van tijd tot tijd wijzigen. De nieuwste versie wordt altijd op onze website geplaatst.</li>
            </ul>
          </div>

          <div>
            <span>Heb je vragen over dit privacybeleid? Neem dan gerust contact met ons op via <a href="mailto:contact@pulcinos.nl">contact@pulcinos.nl</a></span>
            <img src={logo} alt="Pulcinos Liquor Co." />
            <span className="closingTitle"><strong>Pulcinos Liquor Co. –</strong></span>
          </div>

        </div>
      </div>
    </>
  );
}

export default PrivacyBeleid;
