import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getAllOrders } from '../../dataSource/OrderSource';
import './styling/adminOrders.css';

const AdminOrders = ({setActivePage}) => {
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
  const [filters, setFilters] = useState({
    status: '',
    paymentStatus: '',
  });
  const [searchQuery, setSearchQuery] = useState('');

  const [isMobile, setIsMobile] = useState(
    window.matchMedia("(max-width: 1200px)").matches
  );

  useEffect(() => {
    async function fetchData() {
      const result = await getAllOrders();
      if(result){
      const sortedOrders = result.sort((a, b) => b.id - a.id);      
      setOrders(sortedOrders);
      setFilteredOrders(sortedOrders);
    }
    }
    fetchData();
  }, []);

  const paymentStatusMap = {
    Pending: "In afwachting",
    Completed: "Voltooid",
    CANCELLED: "Geannuleerd"
  };
  

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  useEffect(() => {
    let sortedOrders = [...filteredOrders];
    if (sortConfig.key !== null) {
      sortedOrders.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
      });
    }
    setFilteredOrders(sortedOrders);
  }, [sortConfig]);

  useEffect(() => {
    let filtered = orders;
    if (filters.status) {
      filtered = filtered.filter(order => order.status === filters.status);
    }
    if (filters.paymentStatus) {
      filtered = filtered.filter(order => order.payment_status === filters.paymentStatus);
    }
    if (searchQuery) {
      filtered = filtered.filter(order =>
        order.customer.email_address.toLowerCase().includes(searchQuery.toLowerCase()) ||
        order.address.city.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }
    setFilteredOrders(filtered);
  }, [filters, searchQuery, orders]);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({ ...filters, [name]: value });
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  return (
    <>
    <div id='adminOrdersWrapper'>
      <div className="container">
        <h2 className='adminHeader'>Alle Bestellingen</h2>
        <div className="filters lightBorder">
          <h3 className='adminHeader'>Filters</h3>
          <div >          
          <div id="orderSearchBar">
          <i className="bi bi-search"></i>
          <input
              type="text"
              placeholder="Zoek op klant email of stad"
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </div>   
          <div className='filterWrapper lightBorder'>
          <label>
            <strong>Status:</strong>
            <select name="status" value={filters.status} onChange={handleFilterChange}>
              <option value="">Alle</option>
              <option value="Ontvangen">Ontvangen</option>
              <option value="Verwerkt">Verwerkt</option>
              <option value="Verzonden">Verzonden</option>
              <option value="Afgerond">Afgerond</option>
            </select>
          </label>   
          </div>

          <div className='filterWrapper lightBorder'>
          <label>
            <strong>Betaling Status:</strong>
            <select name="paymentStatus" value={filters.paymentStatus} onChange={handleFilterChange}>
              <option value="">Alle</option>
              <option value="Pending">In afwachting</option>
              <option value="Completed">Voltooid</option>
              <option value="CANCELLED">Geannuleerd</option>
            </select>
          </label>
          </div>
          </div>           
        </div>

        <div className='lightBorder'>
        {!isMobile ? (
        <table>
          <thead>
            <tr>
              <th onClick={() => handleSort('id')}>ID</th>
              <th onClick={() => handleSort('price')}>Prijs</th>
              <th onClick={() => handleSort('customer.email_address')}>Klant Email</th>
              <th onClick={() => handleSort('address.city')}>Stad</th>
              <th onClick={() => handleSort('order_date')}>Besteldatum</th>
              <th onClick={() => handleSort('status')}>Status</th>
              <th onClick={() => handleSort('payment_status')}>Betaling Status</th>
              <th>Acties</th>
            </tr>
          </thead>       
          <tbody>
            {filteredOrders.map(order => (
              <tr key={order.id}>
                <td>{order.id}</td>
                <td>€{(order.price).toFixed(2)}</td>
                <td>{order.customer.email_address}</td>
                <td>{order.address.city}</td>
                <td>{order.order_date}</td>
                <td>{order.status}</td>
                <td>{paymentStatusMap[order.payment_status] || order.payment_status}</td>
                <td>
                  <button onClick={() => setActivePage(`order-details-${order.id}`)} className='btn-download'>Details Bekijken</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        ) : (
        <table>           
         <tbody>
            {filteredOrders.map(order => (
              <tr key={order.id}>
                <td><span>ID:</span><span>{order.id}</span></td>
                <td><span>Prijs:</span><span>€{(order.price).toFixed(2)}</span></td>
                <td><span>Email:</span><span>{order.customer.email_address}</span></td>
                <td><span>Stad:</span><span>{order.address.city}</span></td>
                <td><span>Datum:</span><span>{order.order_date}</span></td>
                <td><span>Status:</span><span>{order.status}</span></td>
                <td><span>Betalings Status:</span><span>{paymentStatusMap[order.payment_status] || order.payment_status}</span></td>
                <td>
                  <button onClick={() => setActivePage(`order-details-${order.id}`)} className='btn-download'>Details Bekijken</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        )}
        </div>

      </div>
    </div>
    </>
  );
};

export default AdminOrders;
