import React, { useState, useEffect } from 'react';
import './styling/admin.css';
import { Link } from 'react-router-dom';
import { getAllOrders } from '../../dataSource/OrderSource';
import AdminSideBar from './AdminSideBar';
import logoWhite from "../../pages/admin/assets/mailLogo.png";

function AdminHome() {

  const [isMobile, setIsMobile] = useState(
    window.matchMedia("(max-width: 1200px)").matches
  );

  const [username, setUsername] = useState(null); 

  useEffect(() => {
    const storedUsername = localStorage.getItem('Username');
    setUsername(storedUsername); 

    console.log("Username: ", storedUsername); 
  }, []);


  return (
    <>      
      <div id='adminHomeBox'>

        <img src={logoWhite} alt=''></img>

        <h2>Welkom <span className='green'>{username}</span> op de Administrator Pagina</h2>

      </div>
    </>
  );
}

export default AdminHome;
