const BASE_URL = process.env.REACT_APP_BASE_URL;
const maxRetries = 3;
const retryDelay = 1000;

async function fetchWithRetry(url, options = {}, retries = maxRetries) {
  console.log(`fetchWithRetry called with retries: ${retries}`);
  for (let attempt = 1; attempt <= retries; attempt++) {
    try {
      const response = await fetch(url, options);
      if (response.ok) return response; // Success
      console.error(`Attempt ${attempt} failed: ${response.statusText}`);
    } catch (error) {
      console.error(`Attempt ${attempt} failed with error:`, error);
      if (attempt === retries) throw error; // Re-throw if final attempt
    }
    // Wait before next attempt
    await new Promise(resolve => setTimeout(resolve, retryDelay));
  }
}

export async function loginUser(username, password) {
  try {
    const response = await fetchWithRetry(`${BASE_URL}/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ username, password }),
    });

    if (response.ok) {
      const data = await response.json();
      localStorage.setItem("Token", data.Token);
      localStorage.setItem("Username", username);
      return data;
    } else {
      console.error("Login failed");
      return false;
    }
  } catch (error) {
    console.error("Error during login:", error);
    return false;
  }
}

export function logoutUser() {
  localStorage.removeItem("Token");
  localStorage.removeItem("Username");
  console.log("User logged out");
}

export async function validateToken(token) {
  try {
    const response = await fetchWithRetry(`${BASE_URL}/validate`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.ok) {
      const data = await response.json();
      return data.valid;
    } else {
      console.error("Token validation failed");
      return false;
    }
  } catch (error) {
    console.error("Error validating token:", error);
    return false;
  }
}

export async function registerUser(username, email, password) {

  const token = localStorage.getItem("Token"); 

  try {
    const response = await fetchWithRetry(`${BASE_URL}/register`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`, 
      },
      body: JSON.stringify({ username, email, password }),
    });

    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      console.error("Failed to register user");
      return false;
    }
  } catch (error) {
    console.error("Error registering user:", error);
    return false;
  }
}

export async function fetchUsers() {
    const token = localStorage.getItem("Token"); 
  
    try {
      const response = await fetchWithRetry(`${BASE_URL}/users`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`, 
        },
      });
  
      return response.ok ? await response.json() : [];
    } catch (error) {
      console.error("Error fetching users:", error);
      return [];
    }
  }
  

export async function forgotPassword(email) {
  try {
    const response = await fetchWithRetry(`${BASE_URL}/forgot_password`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email }),
    });

    if (response.ok) {
      return { success: true, message: "Password reset email sent!" };
    } else {
      console.error("Failed to send password reset email");
      return { success: false, message: "Failed to send password reset email" };
    }
  } catch (error) {
    console.error("Error sending password reset email:", error);
    return { success: false, message: "Error sending email" };
  }
}

export async function resetPassword(token, newPassword) {
  try {
    const response = await fetchWithRetry(`${BASE_URL}/reset_password/${token}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ password: newPassword }),
    });

    if (response.ok) {
      return { success: true, message: "Password reset successful!" };
    } else {
      console.error("Failed to reset password");
      return { success: false, message: "Failed to reset password" };
    }
  } catch (error) {
    console.error("Error resetting password:", error);
    return { success: false, message: "Error resetting password" };
  }
}

export async function updatePassword(userId, newPassword) {

    const token = localStorage.getItem("Token"); 
  
    try {
      const response = await fetchWithRetry(`${BASE_URL}/users/${userId}/update-password`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`, 
        },
        body: JSON.stringify({ password: newPassword }),
      });
  
      const data = await response.json();
  
      if (response.ok) {
        return { success: true, message: data.message };
      } else {
        return { success: false, message: data.message || "Failed to reset password" };
      }
    } catch (error) {
      console.error("Error resetting password:", error);
      return { success: false, message: "Error resetting password" };
    }
  }
  
