import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { createProduct } from "../../dataSource/ProductSource";
import logo from "../assets/logoWhite.png";
import ConfirmModal from '../../components/ConfirmModal';
import Slider from "react-slick";



const AddProduct = ({setActivePage}) => {

  const [productType, setProductType] = useState("");
  const [modal, setModal] = useState({ show: false, message: "", action: null });
  const [formData, setFormData] = useState({
    name: "",
    summary: "",
    price: "",
    stock: "",
    onder_titel: "",
    inhoud: "",
    percentage: "",
    temperatuur: "",
    smaak: "",
    lekker_bij: "",
    vinificatie: "",
    beschrijving: "",
    serveren: "",
    sluitzin: "",
    land: "",
    streek: "",
    wijnhuis: "",
    regio: "",
    druif: "",
    stijl: "",
  });

  const [isMobile, setIsMobile] = useState(
          window.matchMedia("(max-width: 1200px)").matches
        );

  const [selectedFiles, setSelectedFiles] = useState([]);
   const [nav1, setNav1] = useState(null);
    const [nav2, setNav2] = useState(null);
  

  const settingsMain = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    lazyLoad: "ondemand", // Lazy load images on demand
    asNavFor: nav2, // Sync with thumbnail slider
    arrows: false,
  };

  const settingsThumbs = {
    slidesToShow: 2,
    slidesToScroll: 1,
    dots: false,
    centerMode: false,
    focusOnSelect: true,
    asNavFor: nav1, // Sync with main slider
    arrows: false,
    infinite: false,
  };

  const navigate = useNavigate();

  // ✅ Handle input changes
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const openPreview = () => {
    const previewWindow = window.open(
      "/admin/products/preview",
      "_blank"
    );
  
    if (previewWindow) {
      const previewData = {
        productData: { ...formData },
        productType: productType,
        selectedFiles: selectedFiles.map((file) => ({
          name: file.name,
          type: file.type,
          url: URL.createObjectURL(file),
        })),
      };
  
      // Poll every 500ms to check if preview window is ready
      const interval = setInterval(() => {
        if (previewWindow && !previewWindow.closed) {
          previewWindow.postMessage({ checkReady: true }, "*");
        } else {
          clearInterval(interval);
        }
      }, 500);
  
      // Listen for confirmation from preview window
      const listener = (event) => {
        if (event.data.ready) {
          clearInterval(interval);
          previewWindow.postMessage(previewData, "*");
          window.removeEventListener("message", listener);
        }
      };
  
      window.addEventListener("message", listener);
    } else {
      alert("Popup geblokkeerd! Sta pop-ups toe in je browser.");
    }
  };
  

  // ✅ Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    setModal({
      show: true,
      message: "Weet je zeker dat je dit product wilt toevoegen?",
      action: async () => {
        

    // Prepare the data in the right structure
    const productData = {
      name: formData.name,
      summary: formData.summary, // General description (2 sentences)
      price: parseFloat(formData.price),
      product_info: {
        product_type: productType,
        naam: formData.name,
        prijs: parseFloat(formData.price),
        stock: parseInt(formData.stock),
        onder_titel: formData.onder_titel,
        inhoud: formData.inhoud ? parseFloat(formData.inhoud) : null,
        percentage: formData.percentage ? parseFloat(formData.percentage) : null,
        temperatuur: formData.temperatuur || null,
        smaak: formData.smaak || null,
        lekker_bij: formData.lekker_bij || null,
        vinificatie: formData.vinificatie || null,
        beschrijving: formData.beschrijving,
        serveren: formData.serveren,
        sluitzin: formData.sluitzin,
        land: formData.land,
        streek: formData.streek || null,
        wijnhuis: formData.wijnhuis || null,
        regio: formData.regio || null,
        druif: formData.druif || null,
        stijl: formData.stijl || null,
        actief: false, 
      },
    };

    await createProduct(productData, selectedFiles);

    setActivePage("products");

    setModal({ show: false, message: "", action: null });
      },
    });
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    if (files.length > 5) {
      alert("Je kunt maximaal 5 afbeeldingen uploaden.");
      return;
    }
    setSelectedFiles(files);
  };

  return (
    <>
      <div className="productOverviewWrapper">   
      <div className="container containerProducts">

        <h2 className="adminHeader">Nieuw Product Toevoegen</h2>
        <p>Voeg hier een product toe, velden met * zijn verplicht</p>
        <p>Bekijk onder aan de pagina een preview</p>

        <div className="productSelectWrapper lightBorder">
        <label className="firstLabel">Selecteer Product Type:</label>
        <select value={productType} onChange={(e) => setProductType(e.target.value)} required>
          <option value="">Kies een type</option>
          <option value="Likeur">Likeur</option>
          <option value="Wijn">Wijn</option>
          <option value="Overig">Overig</option>
        </select>
        </div>

        {/* ✅ Step 2: Show Form Only If Type is Selected */}
        {productType && (
          <form onSubmit={handleSubmit} className="product-form lightBorder">
            <label><h4 className="adminHeader">* Afbeeldingen (max 5):</h4></label>
            <input type="file" multiple accept="image/*" onChange={handleFileChange} />
            <label ><h4 className="adminHeader">* Prijs:</h4></label>
            <input type="number" name="price" placeholder="Prijs (€)" value={formData.price} onChange={handleChange} required />
            <label ><h4 className="adminHeader">* Voorraad:</h4></label>
            <input type="number" name="stock" placeholder="Voorraad" value={formData.stock} onChange={handleChange} required />
            <label ><h4 className="adminHeader">* Naam:</h4></label>
            <input type="text" name="name" placeholder="Productnaam" value={formData.name} onChange={handleChange} required />
            <label ><h4 className="adminHeader">* Ondertitel:</h4></label>
            <input type="text" name="onder_titel" placeholder="Ondertitel" value={formData.onder_titel} onChange={handleChange} />
            <label ><h4 className="adminHeader">* Korte Beschrijving: (voor op webshop overzicht)</h4></label>
            <textarea name="summary" placeholder="Korte beschrijving (2 zinnen)" value={formData.summary} onChange={handleChange} required />
            <label ><h4 className="adminHeader">* Volledige Beschrijving:</h4></label>
            <textarea name="beschrijving" placeholder="Uitgebreide beschrijving" value={formData.beschrijving} onChange={handleChange} required />
            <label ><h4 className="adminHeader">Sluitzin:</h4></label>
            <input type="text" name="sluitzin" placeholder="Sluitzin" value={formData.sluitzin} onChange={handleChange} />
       

            {/* ✅ Fields for Likeur & Wijn */}
            {(productType === "Likeur" || productType === "Wijn") && (
              <>
                <label ><h4 className="adminHeader">Inhoud (in cl):</h4></label>
                <input type="number" name="inhoud" placeholder="Inhoud (cl)" value={formData.inhoud} onChange={handleChange} required />
                <label ><h4 className="adminHeader">Alcohol percentage:</h4></label>
                <input type="number" name="percentage" placeholder="Alcoholpercentage (%)" value={formData.percentage} onChange={handleChange} required />
                <label ><h4 className="adminHeader">Serveertemperatuur (in ˚C):</h4></label>
                <input type="text" name="temperatuur" placeholder="Serveertemperatuur" value={formData.temperatuur} onChange={handleChange} />
                <label ><h4 className="adminHeader">Land:</h4></label>
                <input type="text" name="land" placeholder="Land" value={formData.land} onChange={handleChange} />
                <label ><h4 className="adminHeader">Stijl:</h4></label>
                <input type="text" name="stijl" placeholder="Stijl" value={formData.stijl} onChange={handleChange} />
                <label ><h4 className="adminHeader">Smaakprofiel:</h4></label>
                <input type="text" name="smaak" placeholder="Smaakprofiel" value={formData.smaak} onChange={handleChange} />
                <label ><h4 className="adminHeader">Hoe te serveren:</h4></label> 
                <p className="labelTip">geef meerdere items op door ze te scheiden met ***, voorbeeld: serveertip 1 *** serveertip 2 *** serveertip 3</p>             
                <input type="text" name="serveren" placeholder="Hoe te serveren" value={formData.serveren} onChange={handleChange} />
               
              </>
            )}

            {/* ✅ Fields for Wijn Only */}
            {productType === "Wijn" && (
              <>
                <label ><h4 className="adminHeader">Lekker bij:</h4></label>
                <p className="labelTip">geef meerdere items op door ze te scheiden met ***, voorbeeld: serveertip 1 *** serveertip 2 *** serveertip 3</p>              
                <input type="text" name="lekker_bij" placeholder="Lekker bij" value={formData.lekker_bij} onChange={handleChange} />
                <label ><h4 className="adminHeader">Vinificatie:</h4></label>
                <input type="text" name="vinificatie" placeholder="Vinificatie" value={formData.vinificatie} onChange={handleChange} />
                <label ><h4 className="adminHeader">Streek:</h4></label>
                <input type="text" name="streek" placeholder="Streek" value={formData.streek} onChange={handleChange} />
                <label ><h4 className="adminHeader">Wijnhuis:</h4></label>
                <input type="text" name="wijnhuis" placeholder="Wijnhuis" value={formData.wijnhuis} onChange={handleChange} />
                <label ><h4 className="adminHeader">Regio:</h4></label>
                <input type="text" name="regio" placeholder="Regio" value={formData.regio} onChange={handleChange} />
                <label ><h4 className="adminHeader">Druif:</h4></label>
                <input type="text" name="druif" placeholder="Druif" value={formData.druif} onChange={handleChange} />
              </>
            )}

            <button type="submit" className="btn-save-product"><i class="bi bi-plus-circle"></i> Voeg Product Toe</button>
          </form>
        )}

      </div>    
      <div className="productPreviewButton" onClick={openPreview}>
      <i class="bi bi-eye-fill"></i>
      <span>Voorbeeld Bekijken</span>
       </div>
      </div>

      {/* ✅ Confirmation Modal */}
      <ConfirmModal
        show={modal.show}
        message={modal.message}
        onConfirm={modal.action}
        onCancel={() => setModal({ show: false, message: "", action: null })}
      />
    </>
  );
};

export default AddProduct;
