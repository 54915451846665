// src/components/AdminOrderDetails.js

import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { getOrderById } from '../../dataSource/OrderSource';
import saveIcon from './assets/save.svg';
import completeIcon from './assets/check.svg';
import './styling/adminOrderDetails.css';
import ConfirmModal from '../../components/ConfirmModal';
import { ClipLoader } from 'react-spinners';
import { updateOrder, downloadOrderPdf } from '../../dataSource/OrderSource';
import { getLabelByOrderId } from '../../dataSource/PostNLSource';
import { sendShippingConfirmation, sendPickupConfirmation } from '../../dataSource/MailSource';

const AdminOrderDetails = ({orderId}) => {
  const [initPageLoad, setInitPageLoad] = useState(false);
  // const { id } = useState(orderId);
  const { from } = useParams();
  const [order, setOrder] = useState(null);
  const [updatedStatus, setUpdatedStatus] = useState('');
  const [status, setStatus] = useState('');
  const [statusChanged, setStatusChanged] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [complete, setComplete] = useState(false);
  const [pdfLabelUrl, setPdfLabelUrl] = useState('');

  const [isMobile, setIsMobile] = useState(
    window.matchMedia("(max-width: 1200px)").matches
  );

  const [showEmailModal, setShowEmailModal] = useState(false);
  const [emailMessage, setEmailMessage] = useState("");
  const [emailType, setEmailType] = useState(""); // Determines which function to call
  
  const handleEmailButtonClick = () => {
    if (!order) return;
  
    if (order.shipping_data.delivery_options === "Delivery") {
      setEmailMessage("Weet je zeker dat je de klant een bevestigings email wilt sturen met het bericht dat de bestelling is verzonden?");
      setEmailType("Delivery");
    } else if (order.shipping_data.delivery_options === "Pickup") {
      setEmailMessage("Weet je zeker dat je de klant een bevestigings email wilt sturen dat de bestelling klaar ligt om opgehaald te worden?");
      setEmailType("Pickup");
    }
  
    setShowEmailModal(true);
  };
  
  const handleConfirmSendEmail = async () => {
    setShowEmailModal(false);
  
    
  let response;
  
  if (emailType === "Delivery") {
    response = await sendShippingConfirmation(order);
  } else if (emailType === "Pickup") {
    response = await sendPickupConfirmation(order);
  }
  
    alert("Email succesvol verzonden!");
  };
  
  const paymentStatusMap = {
    Pending: "In afwachting",
    Completed: "Voltooid",
    CANCELLED: "Geannuleerd"
  };
  

  useEffect(() => {
    async function fetchData() {

      console.log("Fetching data", orderId);

      const result = await getOrderById(orderId);
      console.log(result);
      setOrder(result);

      const shippingLabelObject = await getLabelByOrderId(orderId);
    
      if (shippingLabelObject && shippingLabelObject.label_data) {
          const base64Data = shippingLabelObject.label_data; // This is base64 string
          const binaryString = atob(base64Data); // Decodes base64 to binary string
          const len = binaryString.length;
          const bytes = new Uint8Array(len);
          for (let i = 0; i < len; i++) {
              bytes[i] = binaryString.charCodeAt(i);
          }

          const blob = new Blob([bytes], { type: 'application/pdf' });
          const url = URL.createObjectURL(blob);
          setPdfLabelUrl(url); // Set the URL to view/download the PDF
      }
    }
    fetchData();
  }, [orderId]);

  useEffect(() => {
    if (order && initPageLoad === false) {
      setInitPageLoad(true);
      setStatus(order.status);
      setUpdatedStatus(order.status);
    }
  }, [order, initPageLoad]);

  useEffect(() => {
    if (status === updatedStatus){
      setStatusChanged(false);
    } else {
      setStatusChanged(true);
    }  
  }, [updatedStatus, status]);

  const handleStatusChange = (e) => {

    setUpdatedStatus(e.target.value);
    setComplete(false);  

  };

  const handleSaveStatus = () => {
    setShowModal(true);
  };

  const handleConfirm = async () => {
    setShowModal(false);    
    setStatusChanged(false);
    setLoading(true);

    try {
      await updateOrder(order.id, {status: updatedStatus});
    } catch (error) {
      setLoading(false);
      console.log("Error updating order: ", error);
    }
    
    setStatus(updatedStatus);
    setLoading(false);
    setComplete(true);

  };

  const handleCancel = () => {
    setShowModal(false);
  };

  const handleDownloadPdf = async () => {
    const success = await downloadOrderPdf(orderId);
    if (success) {
      console.log('PDF downloaded successfully');
    } else {
      console.error('Failed to download PDF');
    }
  };

  if (!order) {
    return <div className="loading">Laden...</div>;
  }

  return (
    <>
    <div className="detailsContainer">      
      {/* <div className='adminButtonsDiv'>
      {from == "processing" ? (
        <Link to={`/admin/shipping`} className="action-link"><button className='btn-pulci-green'>Terug naar overzicht</button></Link> 
      ) :
      (<Link to={`/admin/orders`} className="action-link"><button className='btn-pulci-green'>Terug naar overzicht</button></Link> )}
          
      {/* {pdfLabelUrl ? (
                <div>
                   <button onClick={handleDownloadPdf} className='btn-download'>Download PDF</button>
                    <a href={pdfLabelUrl} target="_blank" rel="noopener noreferrer">
                        <button className='btn-download'>View Shipping Label</button>
                    </a>
                    <a href={pdfLabelUrl} download="shipping_label.pdf">
                        <button className='btn-download'>Download Shipping Label</button>
                    </a>
                </div>
            ) : (
                <p>Verzendlabel niet beschikbaar.</p>
            )} 
      </div> */}
        
      <h2 className='adminHeader'>Bestelgegevens - Order #{order.id}</h2>

      <div className="details-section">
        
        <div className='lightBorder'>
        <ul className="details-list">
          <h3 className='adminHeader'>Order info</h3>
          <li><span className="label">ID:</span> <span className="value">{order.id}</span></li>
          <li><span className="label">Levering:</span><strong><span className="value">{order.shipping_data.delivery_options}</span></strong></li>
          <li><span className="label">Stuur bevestigings mail:</span> <button className='btn-pulci-green' onClick={handleEmailButtonClick}>Stuur mail</button></li>
          <li><span className="label">Prijs:</span> <span className="value">€{(order.price).toFixed(2)}</span></li>
          <li><span className="label">Besteldatum:</span> <span className="value">{order.order_date}</span></li>
          <li>
            <span className="label">Status:</span>
            <span className="value statusValue">
              {statusChanged && (
                <span className="save-icon" onClick={handleSaveStatus} title="Save"><img src={saveIcon} alt='save'></img></span>
              )}
              {loading && (
                <ClipLoader color="#3498db" loading={loading} size={12} />
              )}
               {complete && (
                <span className="save-icon check-icon" title="Complete"><img src={completeIcon} alt='complete'></img></span>
              )}
              <select value={updatedStatus} onChange={handleStatusChange}>
                <option value="Ontvangen">Ontvangen</option>
                <option value="Verwerkt">Verwerkt</option>
                <option value="Verzonden">Verzonden</option>
                <option value="Afgerond">Afgerond</option>
              </select>
            </span>
          </li>
          <li><span className="label">Betaalstatus:</span> <span className="value">{paymentStatusMap[order.payment_status] || order.payment_status}</span></li>          
        </ul>
        </div>
        {order.address.shipping_address == 1 ? (
         <>
         <div className='lightBorder'>
        <ul className="details-list">
        <h3 className='adminHeader'>Klantgegevens</h3>
          <li><span className="label">Voornaam:</span> <span className="value">{order.customer.first_name}</span></li>
          <li><span className="label">Tussenvoegsel:</span> <span className="value">{order.customer.preposition}</span></li>
          <li><span className="label">Achternaam:</span> <span className="value">{order.customer.last_name}</span></li>
          <li><span className="label">E-mailadres:</span> <span className="value">{order.customer.email_address}</span></li>
          <li><span className="label">Telefoonnummer:</span> <span className="value">{order.customer.phone_number}</span></li>
          <li><span className="label">Bedrijfsnaam:</span> <span className="value">{order.customer.company_name}</span></li>
        </ul>  
        </div>
        <div className='lightBorder'>      
        <ul className="details-list">
        <h3 className='adminHeader'>Adresgegevens</h3>
          <li><span className="label">Land:</span> <span className="value">{order.address.country}</span></li>
          <li><span className="label">Postcode:</span> <span className="value">{order.address.zipcode}</span></li>
          <li><span className="label">Stad:</span> <span className="value">{order.address.city}</span></li>
          <li><span className="label">Straatnaam:</span> <span className="value">{order.address.street_name}</span></li>
          <li><span className="label">Huisnummer:</span> <span className="value">{order.address.house_number}</span></li>
          <li><span className="label">Toevoeging huisnummer:</span> <span className="value">{order.address.house_number_addition}</span></li>
        </ul> 
        </div> 
        </>   
        ):(
          <>
          <div className='lightBorder'>     
          <ul className="details-list">
          <h3 className='adminHeader'>Factuurgegevens</h3>
          <li><span className="label">Voornaam:</span> <span className="value">{order.customer.first_name}</span></li>
          <li><span className="label">Tussenvoegsel:</span> <span className="value">{order.customer.preposition}</span></li>
          <li><span className="label">Achternaam:</span> <span className="value">{order.customer.last_name}</span></li>
          <li><span className="label">E-mailadres:</span> <span className="value">{order.customer.email_address}</span></li>
          <li><span className="label">Telefoonnummer:</span> <span className="value">{order.customer.phone_number}</span></li>
          <li><span className="label">Bedrijfsnaam:</span> <span className="value">{order.customer.company_name}</span></li>
          <br></br>
          <li><span className="label">Land:</span> <span className="value">{order.address.country}</span></li>
          <li><span className="label">Postcode:</span> <span className="value">{order.address.zipcode}</span></li>
          <li><span className="label">Stad:</span> <span className="value">{order.address.city}</span></li>
          <li><span className="label">Straatnaam:</span> <span className="value">{order.address.street_name}</span></li>
          <li><span className="label">Huisnummer:</span> <span className="value">{order.address.house_number}</span></li>
          <li><span className="label">Toevoeging huisnummer:</span> <span className="value">{order.address.house_number_addition}</span></li>
        </ul>    
        </div>  
        <div className='lightBorder'>       
        <ul className="details-list">          
        <h3 className='adminHeader'>Verzendgevens</h3>    
          <li><span className="label">Voornaam:</span> <span className="value">{order.shipping_address.first_name}</span></li>
          <li><span className="label">Tussenvoegsel:</span> <span className="value">{order.shipping_address.preposition}</span></li>
          <li><span className="label">Achternaam:</span> <span className="value">{order.shipping_address.last_name}</span></li>
          <br></br>
          <li><span className="label">Land:</span> <span className="value">{order.shipping_address.country}</span></li>
          <li><span className="label">Postcode:</span> <span className="value">{order.shipping_address.zipcode}</span></li>
          <li><span className="label">Stad:</span> <span className="value">{order.shipping_address.city}</span></li>
          <li><span className="label">Straatnaam:</span> <span className="value">{order.shipping_address.street_name}</span></li>
          <li><span className="label">Huisnummer:</span> <span className="value">{order.shipping_address.house_number}</span></li>
          <li><span className="label">Toevoeging huisnummer:</span> <span className="value">{order.shipping_address.house_number_addition}</span></li>
        </ul>  
        </div>
        </>   

        )}
      <div className='lightBorder'>     
      <ul className="details-list">
      <h3 className='adminHeader'>Bestellijnen</h3>
      {!isMobile && (
      <li >
            <span className="label">Product:</span>
            <span className="label">Prijs:</span>
          </li>
          )}
        {order.order_lines.map(line => (
          <li key={line.id}>
          <span className="value">{line.product.name}</span> 
          <span className="value">€{(line.cost).toFixed(2)}</span>
          </li>
        ))}      
        {order.coupon_usage && (
          <li className='couponLi'>
            <span><strong>Coupon:</strong> {order.coupon_usage.coupon.code}</span>
            <span>- €{order.coupon_usage.discount_value}</span>
          </li>
        )}
          <li>
        <span><h3>Totaal</h3></span> 
        <span className="value"><strong>€{(order.price).toFixed(2)}</strong></span>
        </li>
      </ul>
      </div>


      {order.note !== '' && (        
      <div id='noteBox' className='lightBorder'>
        <h3 className='adminHeader'>Opmerking</h3>
        <span>{order.note}</span>
      </div>      
      )}
      </div>     
      <ConfirmModal
        show={showModal}
        message="Weet je zeker dat je de status wilt updaten?"
        onConfirm={handleConfirm}
        onCancel={handleCancel}
      />
    </div>
    <ConfirmModal
        show={showEmailModal}
        message={emailMessage}
        onConfirm={handleConfirmSendEmail}
        onCancel={() => setShowEmailModal(false)}
      />
    </>
  );
};

export default AdminOrderDetails;
