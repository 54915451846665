import { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { resetPassword } from "../dataSource/UserSource";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const ResetPassword = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const [validationErrors, setValidationErrors] = useState([]);
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [isValid, setIsValid] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [isMobile, setIsMobile] = useState(
    window.matchMedia("(max-width: 1200px)").matches
  );

  // Functie om het wachtwoord te valideren
  const validatePassword = (pwd) => {
    const errors = [];
    if (pwd.length < 14) {
      errors.push("Wachtwoord moet minimaal 14 tekens bevatten.");
    }
    if (!/[A-Z]/.test(pwd)) {
      errors.push("Wachtwoord moet ten minste één hoofdletter bevatten.");
    }
    if (!/[a-z]/.test(pwd)) {
      errors.push("Wachtwoord moet ten minste één kleine letter bevatten.");
    }
    if (!/[0-9]/.test(pwd)) {
      errors.push("Wachtwoord moet ten minste één cijfer bevatten.");
    }
    if (!/[!@#$%^&*(),.?\":{}|<>]/.test(pwd)) {
      errors.push("Wachtwoord moet ten minste één speciaal teken bevatten.");
    }
    return errors;
  };

  // Update het wachtwoord en voer validatie uit
  const handlePasswordChange = (e) => {
    const newPwd = e.target.value;
    setPassword(newPwd);
    const errors = validatePassword(newPwd);
    setValidationErrors(errors);
    setIsValid(errors.length === 0);
    setPasswordsMatch(newPwd === confirmPassword);
  };

  // Update bevestig wachtwoord veld en controleer of ze matchen
  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    setPasswordsMatch(password === e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isValid || !passwordsMatch) {
      setMessage("Controleer of het wachtwoord voldoet aan de vereisten en overeenkomt.");
      return;
    }

    try {
        const result = await resetPassword(token, password);

        setMessage(result.message);
    
        if (result.success) {
          setTimeout(() => navigate("/login"), 3000); // Redirect na succes
        }
      
    } catch (error) {
      console.error("Fout bij het resetten van het wachtwoord:", error);
      setMessage("Er is een fout opgetreden. Probeer het opnieuw.");
    }
  };

  return (
    <>
      {!isMobile && <div className="desktopNavSpacer"></div>}
      <div className="login-wrapper">
        <div className="login-box reset-box">
          <h2 className="adminHeader">Reset je wachtwoord</h2>
          <form onSubmit={handleSubmit}>
            {/* Wachtwoord veld */}
            <div className="input-group mb-3">
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Nieuw wachtwoord"
                value={password}
                onChange={handlePasswordChange}
                required
                className="form-control"
              />
              <span
                className="input-group-text"
                style={{ cursor: "pointer" }}
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? (
                  <i className="bi bi-eye-slash-fill"></i>
                ) : (
                  <i className="bi bi-eye-fill"></i>
                )}
              </span>
            </div>

            {/* Bevestig wachtwoord veld */}
            <div className="input-group mb-3">
              <input
                type={showConfirmPassword ? "text" : "password"}
                placeholder="Bevestig wachtwoord"
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
                required
                className="form-control"
              />
              <span
                className="input-group-text"
                style={{ cursor: "pointer" }}
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              >
                {showConfirmPassword ? (
                  <i className="bi bi-eye-slash-fill"></i>
                ) : (
                  <i className="bi bi-eye-fill"></i>
                )}
              </span>
            </div>

            {/* Wachtwoord match check */}
            {!passwordsMatch && confirmPassword.length > 0 && (
              <div className="alert alert-danger">
                <p>Wachtwoorden komen niet overeen.</p>
              </div>
            )}

            <button
              className="btn btn-add-product"
              type="submit"
              disabled={!isValid || !passwordsMatch}
            >
              Reset wachtwoord
            </button>
          </form>

          {message && <p>{message}</p>}

          {/* Wachtwoord eisen */}
          <div className="mt-3">
            <h4 className="adminHeader">Wachtwoord vereisten:</h4>
            <div className="mt-3">
                <ul style={{ listStyle: "none", padding: 0 }}>
                    <li>
                    <i
                        className={password.length >= 14 ? "bi bi-check-circle-fill text-success" : "bi bi-circle"}
                    ></i>
                    &nbsp; Minimaal 14 tekens
                    </li>
                    <li>
                    <i
                        className={/[A-Z]/.test(password) ? "bi bi-check-circle-fill text-success" : "bi bi-circle"}
                    ></i>
                    &nbsp; Ten minste één hoofdletter
                    </li>
                    <li>
                    <i
                        className={/[a-z]/.test(password) ? "bi bi-check-circle-fill text-success" : "bi bi-circle"}
                    ></i>
                    &nbsp; Ten minste één kleine letter
                    </li>
                    <li>
                    <i
                        className={/[0-9]/.test(password) ? "bi bi-check-circle-fill text-success" : "bi bi-circle"}
                    ></i>
                    &nbsp; Ten minste één cijfer
                    </li>
                    <li>
                    <i
                        className={/[!@#$%^&*(),.?":{}|<>]/.test(password) ? "bi bi-check-circle-fill text-success" : "bi bi-circle"}
                    ></i>
                    &nbsp; Ten minste één speciaal teken (bijv. !@#$%^&amp;*)
                    </li>
                </ul>
                </div>

          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
