import React, { useContext, useEffect, useState } from 'react';
import { CartContext } from '../util/cartContext';
import { validateCoupon } from '../dataSource/CouponSource';
import './styling/cart.css';
import { Link } from "react-router-dom";
import limoncello from "../pages/assets/limoncello.jpg";
import valenti from "../pages/assets/valenti.jpg";
import yuzu from "../pages/assets/yuzu.jpg";
import valentiCoctail from "../pages/assets/valentiCoctail.png";
import yuzuGin from "../pages/assets/gin.jpg";

const CartBasic = ({ pickUp, totalOrderPrice, setTotalOrderPrice, coupon, setCoupon, couponDiscount, setCouponDiscount }) => { 
  const { cart, addToCart, removeFromCart, clearCart, totalPrice, shippingCost } = useContext(CartContext);
  const [productCount, setProductCount] = useState(0);

  useEffect(() => {
      let count = 0;
      cart.map(product => {
        count += product.quantity;
      });
      setProductCount(count);
    }, [cart]);

  const [isMobile, setIsMobile] = useState(
    window.matchMedia("(max-width: 1000px)").matches
  );

  // State for coupon code
  const [couponCode, setCouponCode] = useState('');
  const [addingCoupon, setaddingCoupon] = useState(false);  
  const [couponApplied, setCouponApplied] = useState(false);
  const [error, setError] = useState('');

  const getProductImage = (item) => {
    return item.images?.length > 0 ? item.images[0].image_url : "https://via.placeholder.com/150";
  };

  const handleApplyCoupon = async () => {

    setaddingCoupon(true);

    if(!pickUp){
    setTotalOrderPrice(totalPrice + shippingCost);
    }else{
      setTotalOrderPrice(totalPrice)
    }

    if (!couponCode) {
      setError('Voer alstublieft een code in');
      setaddingCoupon(false);
      return;
    }
  
    const result = await validateCoupon(couponCode);
  
    if (result.error) {
      setError(result.error); 
      setaddingCoupon(false);
    } else if (result.coupon) {
      setaddingCoupon(false);
      setCoupon(result.coupon);
      setError(''); 
    }
  };

  const handleRemoveCoupon = async () => {

    if(!pickUp){
    setTotalOrderPrice(totalPrice + shippingCost);
    }else{
      setTotalOrderPrice(totalPrice)
    }
      setCouponApplied(false);
      setCoupon('');
      setError(''); 
    }


  useEffect(() => {

    if (!coupon) return;

    console.log("Coupon updated: ", coupon);

    // Validate coupon status and dates
    if (coupon.status !== "active") {
      setError("Deze coupon is niet (meer) actief.");
      setCouponApplied(false);
      return;
    }

    if (coupon.usage_limit != null) {

      if(coupon.usage_count >= coupon.usage_limit){
      setError("Deze coupon is al gebruikt.");
      setCouponApplied(false);
      return;
      }
    }

    const currentDate = new Date();
    const startDate = new Date(coupon.start_date);
    const endDate = new Date(coupon.end_date);

    const formatDate = (date) => {
      return date.toLocaleDateString("nl-NL", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric"
      });
    };

    if (currentDate < startDate) {
      setError("Deze coupon is geldig vanaf: " + formatDate(startDate));
      setCouponApplied(false);
      return;
    }

    if (currentDate > endDate) {
      setError("Deze coupon is verlopen op: " + formatDate(endDate));
      setCouponApplied(false);
      return;
    }

    let applicableCartItems = [];

    if (coupon.applicable_products === "ALL_PRODUCTS") {
      applicableCartItems = cart;
    } else if (Array.isArray(coupon.applicable_products)) {
      applicableCartItems = cart.filter(item => 
        coupon.applicable_products.includes(item.id)
      );
    } else {
      applicableCartItems = [];
    }

    console.log("Products: ", applicableCartItems.length, applicableCartItems);

    if (applicableCartItems.length == 0){
      setError(`Coupon niet toepasbaar op producten in winkelwagentje.`);
      setCouponApplied(false);
      return
    }   

    let discountAmount = 0;

    if(coupon.discount_type !== 'shipping' ){
    
    // Calculate total cart value
    const totalCartValue = cart.reduce((sum, item) => sum + item.price * item.quantity, 0);

    // Check minimum order value
    if (totalCartValue < parseFloat(coupon.minimum_order_value)) {
      setError(`Je moet minimaal €${coupon.minimum_order_value} besteden om deze coupon te gebruiken.`);
      setCouponApplied(false);
      return;
    }

    // Calculate discount    

    if (coupon.discount_type === "percentage") {
      discountAmount = applicableCartItems.reduce((sum, item) => 
        sum + (item.price * item.quantity * (parseFloat(coupon.discount_value) / 100)), 0
      );
    } else {
      discountAmount = parseFloat(coupon.discount_value);
      // Ensure we don't exceed the total of applicable items
      const applicableTotal = applicableCartItems.reduce((sum, item) => sum + item.price * item.quantity, 0);
      discountAmount = Math.min(discountAmount, applicableTotal);
    }
   
    } else {

      if(totalPrice >= 100 || shippingCost == 0 || pickUp){
        setError(`Bestelling profiteert al van gratis verzending.`);
        setCouponApplied(false);
      return;
      }else{        
      discountAmount = 5.95;
      }
    }

    setCouponDiscount(discountAmount);
    setTotalOrderPrice(totalOrderPrice - discountAmount);
    setCouponApplied(true);
    setError('');



  }, [coupon, cart]);

  useEffect(() => {

    if (couponApplied === false){
      return;
    } else{
      if (coupon.discount_type !== 'shipping'){   
        setTotalOrderPrice(totalPrice - couponDiscount); 
        return;
      }else{
        if (pickUp){
          setCouponApplied(false);
          setCouponDiscount(0);
          setError('Bestelling profiteert al van gratis verzending.');
          setTotalOrderPrice(totalPrice);
        }
      }
    }

  }, [pickUp]);

  useEffect(() => {

    console.log("new total order price: ", totalOrderPrice);
  }, [totalOrderPrice]);


  return (
    <div className='cart-basic-items'>
      <h2>Overzicht</h2>
      {cart.length === 0 ? (
        <p>Het winkelwagentje is leeg</p>
      ) : (
        <ul>
          {cart.map(item => (
            <li key={item.id}>
              <div className="item-info">
                <div className='item-info-imageWrapper'>
                  <img src={getProductImage(item)} alt={item.name} className="product-image" />
                </div>
                <div className='item-info-text'>
                  {!isMobile ? ( <h3>{item.name} <span className='white'>({item.quantity})</span></h3>) : 
                  (<h3>{item.name}</h3>)}
                  <p>({item.quantity}) Prijs: €{(item.price * item.quantity).toFixed(2)}</p>
                </div>
              </div>
            </li>
          ))}
        </ul>
      )}

      <Link to="/cart"><p className='btn-bewerk'>Bewerk bestelling</p></Link>

      <div className='priceDetails'>
        <div>
          <h3>Producten ({productCount}) </h3>
          <h3>€ {totalPrice.toFixed(2)}</h3>
        </div>
        <div>
          <h3>Verzendkosten </h3>
          <h3>€ {!pickUp ? (shippingCost.toFixed(2)) : ((0).toFixed(2))}</h3>        
        </div>
        {(totalPrice < 100 && !pickUp) && (<p className={couponApplied ? "coupon-applied-border" : undefined}>Voeg nog&nbsp;<span className="yellow">€ {(100 - totalPrice).toFixed(2)}</span>&nbsp;toe om te profiteren van gratis bezorging!</p>)}
        {couponApplied &&
        <>
        <div>
        <h3>Subtotaal </h3>
        <h3>€ {!pickUp ? ((totalPrice + shippingCost).toFixed(2)) : (totalPrice.toFixed(2))}</h3>        
        </div>
        
        <div className='couponBox'>
          <div>
          <h3>
            Coupon: <span className='yellow'>{coupon.code}</span>
          </h3>
          <h3>- € {couponDiscount.toFixed(2)}</h3>     
          </div>   
          <span>{coupon.description}</span>
        </div>
        </>
        }     
        <div>
          <h3>Totaal </h3>
          <h3>€ {totalOrderPrice.toFixed(2)}</h3>
        </div>
      </div>

      {/* Coupon Section */}
      <div className="coupon-section">
        <label htmlFor="couponCode">Coupon Code:</label>
        <div className="coupon-input-wrapper">
          <input
            id="couponCode"
            type="text"
            value={couponCode}
            onChange={(e) => setCouponCode(e.target.value)}
            placeholder="Voer je couponcode in"
          />
          <div>
          {(!couponApplied || !isMobile) && <button disabled={addingCoupon} onClick={handleApplyCoupon}>{isMobile ? ("Toepassen") : ("Pas Coupon toe")}</button>}
          {couponApplied && <button className="btn-remove-coupon" onClick={handleRemoveCoupon}><i class="bi bi-trash"></i></button>}
          </div>
        </div>
        {error && <p className="error-message">{error}</p>}
        {couponApplied && <p className="coupon-success">Coupon {coupon.code} toegepast!</p>}
      </div>
    </div>
  );
};

export default CartBasic;
