import React, { useState, useEffect } from "react";
import CocktailGrid from "../components/cocktails/CocktailGrid";
import CategoryFilter from "../components/cocktails/CategoryFilter";
import SearchBar from "../components/cocktails/SearchBar";
import cocktails from "../components/cocktails/data/cocktails.json";
import './styling/cocktails.css';

function Cocktails() {
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [searchTerm, setSearchTerm] = useState("");

  const [isMobile, setIsMobile] = useState(
    window.matchMedia("(max-width: 1200px)").matches
  );

  // Prevent page opening halfway scrolled down
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const filteredCocktails = cocktails.filter(
    (cocktail) =>
      (selectedCategory === "All" || cocktail.category === selectedCategory) &&
      cocktail.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
    {!isMobile && (
      <div className="desktopNavSpacer"></div>
       )}

       
    <div className="cocktailBox">

      <div className="coctailTitleWrapper">
        <h1>Receptenboek</h1>
      </div>

      {/* <div className="cocktailFilters">

      <SearchBar setSearchTerm={setSearchTerm} />
      <CategoryFilter
        categories={["All", "Pulcinos Valenti", "Pulcinos Limoncello", "Pulcinos Yuzu Gin"]}
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
      />

      </div> */}

      <CocktailGrid cocktails={filteredCocktails} />
   
    </div>
    </>
  );
}

export default Cocktails;
