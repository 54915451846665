import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { registerUser } from "../../dataSource/UserSource";
import ConfirmModal from "../../components/ConfirmModal";

const AddUser = ({ setActivePage }) => {
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [passwordErrors, setPasswordErrors] = useState([]);
  const [modal, setModal] = useState({ show: false, message: "", action: null });
  const navigate = useNavigate();

  // Validate password strength
  const validatePassword = (pwd) => {
    const errors = [];
    if (pwd.length < 14) errors.push("Minimaal 14 tekens.");
    if (!/[A-Z]/.test(pwd)) errors.push("Minimaal 1 hoofdletter.");
    if (!/[a-z]/.test(pwd)) errors.push("Minimaal 1 kleine letter.");
    if (!/[0-9]/.test(pwd)) errors.push("Minimaal 1 cijfer.");
    if (!/[!@#$%^&*(),.?":{}|<>]/.test(pwd)) errors.push("Minimaal 1 speciaal teken.");
    return errors;
  };

  // Handle input changes
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Handle password validation
  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setFormData({ ...formData, password: newPassword });
    setPasswordErrors(validatePassword(newPassword));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (passwordErrors.length > 0 || formData.password !== formData.confirmPassword) {
      return;
    }

    setModal({
      show: true,
      message: "Weet je zeker dat je deze gebruiker wilt registreren?",
      action: async () => {
        await registerUser(formData.username, formData.email, formData.password);
        setModal({ show: false, message: "", action: null });
        setActivePage("gebruikers");
      },
    });
  };

  return (
    <div className="productOverviewWrapper">
      <div className="container containerProducts addUserBox">
        <h2 className="adminHeader">Gebruiker Registreren</h2>
        <p>Vul de gegevens in om een nieuwe gebruiker toe te voegen.</p>
        <p>Velden met een * zijn verplicht.</p>
        <form onSubmit={handleSubmit} className="lightBorder">
          <label>* Gebruikersnaam:</label>
          <input type="text" name="username" value={formData.username} onChange={handleChange} required />
          
          <label>* Email:</label>
          <input type="email" name="email" value={formData.email} onChange={handleChange} required />
          
          <label>* Wachtwoord:</label>
          <input type="password" name="password" value={formData.password} onChange={handlePasswordChange} required />
          
          <label>* Bevestig Wachtwoord:</label>
          <input type="password" name="confirmPassword" value={formData.confirmPassword} onChange={handleChange} required />
          
          {/* Show password requirements */}
          <ul>
            {passwordErrors.map((error, index) => (
              <li key={index} className="error">{error}</li>
            ))}
          </ul>
          
          <button type="submit" className="btn-save-product" disabled={passwordErrors.length > 0 || formData.password !== formData.confirmPassword}>
            Gebruiker Registreren
          </button>
        </form>
      </div>

      {/* Confirmation Modal */}
      <ConfirmModal
        show={modal.show}
        message={modal.message}
        onConfirm={modal.action}
        onCancel={() => setModal({ show: false, message: "", action: null })}
      />
    </div>
  );
};

export default AddUser;
