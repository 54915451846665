import React, { useState, useEffect } from 'react';
import { isAuthenticated } from "../util/protectedRoute";
import { Link, useNavigate } from "react-router-dom";
import { loginUser } from '../dataSource/UserSource';
import './styling/login.css';

const BASE_URL = process.env.REACT_APP_BASE_URL;

const Login = () => {

  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [message, setMessage] = useState("");

  const [isMobile, setIsMobile] = useState(
    window.matchMedia("(max-width: 1200px)").matches
  );

  useEffect(() => {

    const is_Authenticated = async () => {
      var userAuthenticated = false;
      const token = localStorage.getItem("Token");

      if (token) {
        try {
          const decodedToken = JSON.parse(atob(token.split(".")[1])); // Decode token
          var decodedUserId = decodedToken.sub;

          userAuthenticated = await isAuthenticated(decodedUserId);

          console.log(userAuthenticated);

        } catch {
          console.log("Token not in right format...");
        }
      }

      if (userAuthenticated == true){
        navigate(`/user/${decodedUserId}`)        
      }
    };

  }, [navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const result = await loginUser(username, password);
    if (result) {
      window.location.href = "/admin";
    } else {
      setMessage("Login mislukt. Controleer uw gegevens.");
    }
  };

  return (

    <>
    {!isMobile && (
      <div className="desktopNavSpacer"></div>
       )}

    <div className="login-wrapper">
    <div className="login-box">
      <h2 className='adminHeader'>Admin Login</h2>
      <form onSubmit={handleSubmit} className='lightBorder'>
        <input 
          type="text" 
          placeholder="Username" 
          value={username} 
          onChange={(e) => setUsername(e.target.value)} 
        />
        <input 
          type="password" 
          placeholder="Password" 
          value={password} 
          onChange={(e) => setPassword(e.target.value)} 
        />
        <button type="submit" className="btn btn-add-product">Login</button>

        {message && <p>{message}</p>}
        
      <Link to="/user/forgot"><p>Wachtwoord vergeten?</p></Link>
      </form>
      {error && <p>{error}</p>}
    </div>
  </div>
  </>
  );
};

export default Login;
