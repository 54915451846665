import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getAllOrders } from "../../dataSource/OrderSource";
import { getLabelByOrderId } from "../../dataSource/PostNLSource";
import "./styling/adminShipping.css";
import OrderTable from "../../components/OrderTable";

const AdminShipping = ({setActivePage}) => {
  const [orders, setOrders] = useState([]);
  const [receivedOrders, setReceivedOrders] = useState([]);
  const [processedOrders, setProcessedOrders] = useState([]);
  const [pickupOrders, setPickupOrders] = useState([]);
  const [shippedOrders, setShippedOrders] = useState([]);
  const [labelUrls, setLabelUrls] = useState({});
  const [expandedOrders, setExpandedOrders] = useState({});
  const [expandedSections, setExpandedSections] = useState({
    received: false,
    pickup: false, 
    processed: false,
    shipped: false,
  });

  const [isMobile, setIsMobile] = useState(
    window.matchMedia("(max-width: 1200px)").matches
  );

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.matchMedia("(max-width: 1200px)").matches);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    async function fetchData() {
      const result = await getAllOrders();

      if (result) {
        const sortedOrders = result.sort((a, b) => b.id - a.id);
        setOrders(sortedOrders);

        const received = sortedOrders.filter(
          (order) =>
            order.status === "Ontvangen" && order.payment_status === "Completed"
        );
        const pickup = sortedOrders.filter(
          (order) =>
            order.status === "Verwerkt" &&
            order.payment_status === "Completed" &&
            order.shipping_data?.delivery_options === "Pickup"
        );
        const processed = sortedOrders.filter(
          (order) =>
            order.status === "Verwerkt" &&
            order.payment_status === "Completed" &&
            order.shipping_data?.delivery_options === "Delivery"
        );

        const shipped = sortedOrders.filter(
          (order) =>
            order.status === "Verzonden" && order.payment_status === "Completed"
        );

        setReceivedOrders(received);
        setPickupOrders(pickup); // 🔹 Store pickup orders
        setProcessedOrders(processed);
        setShippedOrders(shipped);

        setExpandedSections({
          received: received.length > 0,
          pickup: pickup.length > 0,
          processed: processed.length > 0,
          shipped: shipped.length > 0,
        });
      }

      //await fetchLabels([...received, ...pickup, ...processed]); // Fetch labels for all sections
    }

    fetchData();
  }, []);

  // const fetchLabels = async (orders) => {
  //   const urls = {};
  //   for (const order of orders) {
  //     const shippingLabelObject = await getLabelByOrderId(order.id);
  //     if (shippingLabelObject && shippingLabelObject.label_data) {
  //       const base64Data = shippingLabelObject.label_data;
  //       const binaryString = atob(base64Data);
  //       const len = binaryString.length;
  //       const bytes = new Uint8Array(len);
  //       for (let i = 0; i < len; i++) {
  //         bytes[i] = binaryString.charCodeAt(i);
  //       }

  //       const blob = new Blob([bytes], { type: 'application/pdf' });
  //       const url = URL.createObjectURL(blob);
  //       urls[order.id] = url;
  //     }
  //   }
  //   setLabelUrls(urls);
  // };

  const toggleOrderExpand = (id) => {
    setExpandedOrders((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const toggleSectionExpand = (section) => {
    setExpandedSections((prev) => ({ ...prev, [section]: !prev[section] }));
  };

  return (
    <>
      <div id="adminShippingWrapper">
        <h2 className="adminHeader">Overzicht Openstaande Bestellingen</h2>

        <div className="lightBorder shippingSection">
          <h3
            className="adminHeader"
            onClick={() => toggleSectionExpand("received")}
          >
            {expandedSections.received ? (
              <i class="bi bi-chevron-bar-contract"></i>
            ) : (
              <i class="bi bi-chevron-bar-expand"></i>
            )}
            <span>Nog te verwerken ({receivedOrders.length})</span>
          </h3>
          {expandedSections.received && (
            <OrderTable
              setActivePage={setActivePage}
              orders={receivedOrders}
              isMobile={isMobile}
              expandedOrders={expandedOrders}
              toggleOrderExpand={toggleOrderExpand}
            />
          )}
        </div>

        <div className="lightBorder shippingSection">
          <h3
            className="adminHeader"
            onClick={() => toggleSectionExpand("pickup")}
          >
            {expandedSections.pickup ? (
              <i class="bi bi-chevron-bar-contract"></i>
            ) : (
              <i class="bi bi-chevron-bar-expand"></i>
            )}
            <span>Klaar voor Pickup ({pickupOrders.length})</span>
          </h3>
          {expandedSections.pickup && (
            <OrderTable
            setActivePage={setActivePage}
              orders={pickupOrders}
              isMobile={isMobile}
              expandedOrders={expandedOrders}
              toggleOrderExpand={toggleOrderExpand}
            />
          )}
        </div>

        <div className="lightBorder shippingSection">
          <h3
            className="adminHeader"
            onClick={() => toggleSectionExpand("processed")}
          >
            {expandedSections.processed ? (
              <i class="bi bi-chevron-bar-contract"></i>
            ) : (
              <i class="bi bi-chevron-bar-expand"></i>
            )}
            <span>Nog te verzenden ({processedOrders.length})</span>
          </h3>
          {expandedSections.processed && (
            <OrderTable
            setActivePage={setActivePage}
              orders={processedOrders}
              isMobile={isMobile}
              expandedOrders={expandedOrders}
              toggleOrderExpand={toggleOrderExpand}
            />
          )}
        </div>

        <div className="lightBorder shippingSection">
          <h3
            className="adminHeader"
            onClick={() => toggleSectionExpand("shipped")}
          >
            {expandedSections.shipped ? (
              <i class="bi bi-chevron-bar-contract"></i>
            ) : (
              <i class="bi bi-chevron-bar-expand"></i>
            )}
            <span>Verzonden ({shippedOrders.length})</span>
          </h3>
          {expandedSections.shipped && (
            <OrderTable
              setActivePage={setActivePage}
              orders={shippedOrders}
              isMobile={isMobile}
              expandedOrders={expandedOrders}
              toggleOrderExpand={toggleOrderExpand}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default AdminShipping;
