import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { getCouponById, updateCoupon } from "../../dataSource/CouponSource"; // assuming you have these functions
import { fetchProducts } from "../../dataSource/ProductSource";
import logo from "../assets/logoWhite.png";
import ConfirmModal from '../../components/ConfirmModal';

const AdminEditCoupon = ({id, setActivePage}) => {
  const [modalAdd, setModalAdd] = useState({ show: false, message: "", action: null });
  const [modal, setModal] = useState({ show: false, message: "", action: null });
  const [isMobile, setIsMobile] = useState(window.matchMedia("(max-width: 1200px)").matches);
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    discountType: "percentage",  // Default to percentage, will update later
    discountValue: "",
    maxDiscountValue: "",
    minimumOrderValue: "",
    startDate: "",
    endDate: "",
    code: "", // coupon code should be fetched and displayed, but not editable
    usageLimit: "",
    status: "active",
    applicableProducts: [],
    active: true,
  });
  const [products, setProducts] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const navigate = useNavigate();

  // Handle input changes
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Fetch coupon data by id when the component loads
  useEffect(() => {
    loadCoupon();
    loadProducts();
  }, []);

  const loadCoupon = async () => {
    const data = await getCouponById(id);
    console.log("Found coupon: ", data);
  
    // Parse dates into the correct format (YYYY-MM-DD)
    const formatDate = (dateString) => {
      const date = new Date(dateString);
      return date.toISOString().split("T")[0]; // Format the date to YYYY-MM-DD
    };
  
    // Set the formData with the fetched coupon details
    setFormData({
      name: data.name || "",
      description: data.description || "",
      discountType: data.discount_type || "percentage",  // Ensure correct value
      discountValue: data.discount_value || "",
      maxDiscountValue: data.max_discount_value || "",
      minimumOrderValue: data.minimum_order_value || "",
      startDate: formatDate(data.start_date) || "", // Format date here
      endDate: formatDate(data.end_date) || "", // Format date here
      code: data.code || "",
      usageLimit: data.usage_limit || "",
      status: data.status || "active",
      applicableProducts: Array.isArray(data.applicable_products) ? data.applicable_products : JSON.parse(data.applicable_products || "[]"),  // Parsing the string if needed
      active: data.active || true,
    });
  };

  const loadProducts = async () => {
    const data = await fetchProducts();
    console.log("found products: ", data);
    setProducts(data);
  };

  const handleProductSelect = (productId) => {
    let updatedProducts = [...formData.applicableProducts];
    if (updatedProducts.includes(productId)) {
      updatedProducts = updatedProducts.filter(id => id !== productId);
    } else {
      updatedProducts.push(productId);
    }
    setFormData({ ...formData, applicableProducts: updatedProducts });
  };

  const handleSelectAllToggle = () => {
    if (selectAll) {
      setFormData({ ...formData, applicableProducts: [] });
    } else {
      setFormData({ ...formData, applicableProducts: 'ALL_PRODUCTS'});
    }
    setSelectAll(!selectAll);
  };
  
  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    setModalAdd({
      show: true,
      message: "Weet je zeker dat je deze coupon wilt bijwerken?",
      action: async () => {
        const couponData = {
          id: id, // Keep the id for updating the coupon
          code: formData.code, // Code stays the same
          description: formData.description,
          discount_type: formData.discountType,
          discount_value: parseFloat(formData.discountValue),
          max_discount_value: parseFloat(formData.maxDiscountValue),
          minimum_order_value: formData.minimumOrderValue ? parseFloat(formData.minimumOrderValue) : null,
          start_date: formData.startDate,
          end_date: formData.endDate,
          usage_limit: formData.usageLimit ? parseInt(formData.usageLimit) : null,
          usage_count: 0, // usage_count remains the same for updating
          status: formData.status,
          applicable_products: formData.applicableProducts || [],
          active: formData.active,
        };

        await updateCoupon(id, couponData);
        setActivePage("coupons");

        setModalAdd({ show: false, message: "", action: null });
      },
    });
  };

  return (
    <>
      <div className="productOverviewWrapper">
        <div className="container containerProducts">

          <h2 className="adminHeader">Coupon Bewerken</h2>
          <p>Pas hier de gegevens van de coupon aan, velden met * zijn verplicht</p>

          <form onSubmit={handleSubmit} className="coupon-form lightBorder">

          <h3 className="adminHeader">Couponcode: <strong>{formData.code}</strong></h3>
                 
            <label><h4 className="adminHeader">* Beschrijving:</h4></label>
            <textarea
              name="description"
              placeholder="Beschrijving van de coupon"
              value={formData.description}
              onChange={handleChange}
              required
            />

            <label><h4 className="adminHeader">* Korting Type:</h4></label>
            <select
              name="discountType"
              value={formData.discountType}
              onChange={handleChange}
              required
            >
              <option value="percentage">Percentage</option>
              <option value="fixed">Vast bedrag</option>
              <option value="shipping">Gratis Verzending</option>
            </select>

            {formData.discountType !== 'shipping' && (
            <>
            <label><h4 className="adminHeader">* Korting Waarde in % of €:</h4></label>
            <input
              type="number"
              name="discountValue"
              placeholder="Kortingswaarde"
              value={formData.discountValue}
              onChange={handleChange}
              required
            />

            <label><h4 className="adminHeader">Maximale korting in €: (laat leeg voor onbeperkt)</h4></label>
            <input
              type="number"
              name="maxDiscountValue"
              placeholder="Maximale Kortingswaarde"
              value={formData.maxDiscountValue}
              onChange={handleChange}
            />
            </>
            )}

            <label><h4 className="adminHeader">Minimale Bestelwaarde:</h4></label>
            <input
              type="number"
              name="minimumOrderValue"
              placeholder="Minimale bestelwaarde"
              value={formData.minimumOrderValue}
              onChange={handleChange}
            />

            <label><h4 className="adminHeader">* Geldig vanaf:</h4></label>
            <input
              type="date"
              name="startDate"
              value={formData.startDate}
              onChange={handleChange}
              required
            />

            <label><h4 className="adminHeader">* Geldig tot:</h4></label>
            <input
              type="date"
              name="endDate"
              value={formData.endDate}
              onChange={handleChange}
              required
            />

        
            <label><h4 className="adminHeader">Gebruikslimiet: (laat leeg voor onbeperkt)</h4></label>
            <input
              type="number"
              name="usageLimit"
              placeholder="Gebruikslimiet"
              value={formData.usageLimit}
              onChange={handleChange}
            />

            <label><h4 className="adminHeader">Status:</h4></label>
            <select
              name="status"
              value={formData.status}
              onChange={handleChange}
            >
              <option value="active">Actief</option>
              <option value="inactive">Inactief</option>
              <option value="expired">Verlopen</option>
              <option value="disabled">Uitgeschakeld</option>
            </select>

            <label><h4 className="adminHeader">* Toepasbare Producten:</h4></label>
            <input
                type="text"
                value={
                  formData.applicableProducts === "ALL_PRODUCTS"
                    ? "Alle Producten"
                    : Array.isArray(formData.applicableProducts)
                    ? formData.applicableProducts.join(", ")
                    : ""
                }
                onFocus={() => setModal({ show: true, message: "Selecteer producten", action: null })}
                readOnly
              />
            <button type="submit" className="btn-save-product">Update Coupon</button>
          </form>
        </div>
      </div>

      {/* Confirmation Modal */}
      <ConfirmModal
        show={modalAdd.show}
        message={modalAdd.message}
        onConfirm={modalAdd.action}
        onCancel={() => setModalAdd({ show: false, message: "", action: null })}
      />

      {/* Product Selection Modal */}
      {modal.show && (
        <div className="product-select-modal-wrapper">
        <div className="product-select-modal">
          <div className="modal-content">
            <h3 className="adminHeader">Selecteer Producten</h3>
            <button  onClick={handleSelectAllToggle}>
            <i class="bi bi-check2-square"></i>  {selectAll ? "Deselecteer Alles" : "Selecteer Alles"}
            </button>

            <div className="product-list lightBorder">
              {Object.values(products).map((product) => (
                <div key={product.id} className="product-item">
                  <input
                    type="checkbox"
                    checked={
                      formData.applicableProducts === "ALL_PRODUCTS" ||
                      formData.applicableProducts.includes(product.id)
                    }
                    onChange={() => handleProductSelect(product.id)}
                    disabled={formData.applicableProducts === "ALL_PRODUCTS"} 
                  />
                  <span>{product.name}</span> - <span>{product.price}</span>
                </div>
              ))}
            </div>

            <button onClick={() => setModal({ show: false, message: "", action: null })}><i class="bi bi-x-square"></i></button>
          </div>
        </div>
        </div>
      )}
    </>
  );
};

export default AdminEditCoupon;
