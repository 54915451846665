import React, { useState, useContext, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './styling/thankYou.css';
import { CartContext } from '../util/cartContext'; 

const ThankYou = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const { clearCart } = useContext(CartContext); 
  
  // Extracting parameters from URL
  const orderId = queryParams.get("order_id");
  const status = queryParams.get("status");

  const [isMobile, setIsMobile] = useState(
    window.matchMedia("(max-width: 1200px)").matches
  );

  useEffect(() => {
    if (status === "COMPLETED") {
      clearCart();
    } 
  }, [status, clearCart]);

  return (
    <>
      {!isMobile && <div className="desktopNavSpacer"></div>}

      <div id="thankYouWrapper">
        <div id="thankYouBox">
          {status === "COMPLETED" ? (
            <>
              <h1>Bedankt voor je bestelling!</h1>
              <p>We hebben je bestelling succesvol ontvangen en zijn deze nu aan het verwerken.</p>
              <p>Een wordt een bevestigingsmail verstuurd naar je e-mailadres.</p>
            </>
          ) : (
            <>
              <h1>Er is iets misgegaan!</h1>
              <p>Helaas is de betaling voor je bestelling niet geslaagd.</p>
              <p>Probeer opnieuw te betalen of neem contact met ons op.</p>
            </>
          )}

          <p><strong>Bestelnummer:</strong> {orderId || "Niet beschikbaar"}</p>

          <Link to="/webshop#productBoxWrapper" className="btn-pulci-green">Terug naar Webshop</Link>
        </div>
      </div>
    </>
  );
};

export default ThankYou;
