import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { CartContext} from "../../util/cartContext";
import { AgeVerificationContext } from "../../util/AgeVerificationContext";
import { fetchProductById } from "../../dataSource/ProductSource"; 
import "./styling/productPage.css";
import "./styling/productPage-s.css";
import "./styling/productPage-m.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import arrow from "./assets/arrow-back.svg";
import logo from "../assets/logoWhite.png";
import nix from "./assets/nix.png";

const ProductPage = () => {
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [statusMessage, setStatusMessage] = useState("");

  const { cart, addToCart, cartMessage, cartMessageType  } = useContext(CartContext);
  const { isOver18, setIsOver18 } = useContext(AgeVerificationContext);

  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
 
  const [isMobile, setIsMobile] = useState(
    window.matchMedia("(max-width: 1200px)").matches
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchProduct = async () => {
      const data = await fetchProductById(id);
      if (data) setProduct(data);
      if (data) console.log(data);
    };
    fetchProduct();
  }, [id]);

  if (!product) return <p>Laden...</p>;

  const addProductToCart = () => {
    addToCart(product, quantity);
    setQuantity(1);
  };

  const handleQuantityChange = (e) => {
    const value = parseInt(e.target.value, 10);
    const maxStock = product.product_info?.stock !== null ? product.product_info.stock : 20;

    if (!isNaN(value) && value >= 1 && value <= maxStock) {
      setQuantity(value);
    } else if (value > maxStock) {
      setQuantity(maxStock);
    }
  };

  const settingsMain = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    lazyLoad: "ondemand",
    asNavFor: nav2,
    arrows: false,
  };

  const settingsThumbs = {
    slidesToShow: 2,
    slidesToScroll: 1,
    dots: false,
    centerMode: false,
    focusOnSelect: true,
    asNavFor: nav1,
    arrows: false,
    infinite: false,
  };

  return (
    <>
      {!isOver18 && (
        <div id="ageWrapper">
          <div id="ageBox">
            <h1>Ben je 18 jaar of ouder?</h1>
            <div id="ageButtons">
              <button className="checkout-btn yes-btn" onClick={() => setIsOver18(true)}>Ja</button>
              <Link to="/"><button className="checkout-btn">Nee</button></Link>
            </div>
          </div>
        </div>
      )}

      {!isMobile && <div className="desktopNavSpacer"></div>}
   <div className="back">
          <Link
            className="hightlightsLink"
            to="/webshop"
            style={{
              textDecoration: "none",
              display: "flex",
              alignItems: "center",
            }}
          >
            <img src={arrow} alt=""></img>
            <p>Terug naar Webshop</p>
          </Link>
        </div>

      <div className="product-page">
        {/* 🔹 Product Image Section */}
        <div className="product-image">
          {product.images && product.images.length > 0 ? (
            product.images.length > 1 ? (
              <>
                <Slider
                  {...settingsMain}
                  ref={(slider) => setNav1(slider)}
                  className="slider-for"
                >
                  {product.images.map((image, index) => (
                    <div key={index}>
                      <img
                        src={image.image_url}
                        alt={`slide-${index}`}
                        style={{ width: "100%", height: "600px", objectFit: "cover" }}
                      />
                    </div>
                  ))}
                </Slider>
                <Slider
                  {...settingsThumbs}
                  ref={(slider) => setNav2(slider)}
                  className="slider-nav"
                >
                  {product.images.map((image, index) => (
                    <div key={index}>
                      <img
                        src={image.image_url}
                        alt={`thumbnail-${index}`}
                        style={{
                          width: "100%",
                          height: "100px",
                          objectFit: "cover",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  ))}
                </Slider>
              </>
            ) : (
              <img src={product.images[0].image_url} alt="Product image" className="slider-main-image single-image" />
            )
          ) : (
            <p>Geen afbeeldingen beschikbaar</p>
          )}
        </div>

        {/* 🔹 Product Info Section */}
        <div className="product-content">
          <div className="productTitle">
            <img src={logo} alt="Pulcinos logo" />
            <h1>{product.name}</h1>
            {product.product_info?.onder_titel && <p className="description">{product.product_info.onder_titel}</p>}
            <h2>€{product.price.toFixed(2)}</h2>
          </div>

          <div className="product-description">
            <p>{product.product_info?.beschrijving || "Geen beschrijving beschikbaar"}</p>

            {product.product_info?.product_type === "Wijn" ? (
              <ul>
                {product.product_info?.inhoud && <li><strong>Inhoud:</strong> {product.product_info.inhoud}cl</li>}
                {product.product_info?.percentage && <li><strong>Alcohol:</strong> {product.product_info.percentage}%</li>}
                {product.product_info?.temperature && <li><strong>Serveertemperatuur:</strong> {product.product_info.temperature}°C</li>}
                {product.product_info?.serveren && <li><strong>Serveren:</strong> {product.product_info.serveren}</li>}                
                
                {(product.product_info?.druif || product.product_info?.smaak || product.product_info?.stijl || product.product_info?.vinificatie) && <br></br>}
                {product.product_info?.druif && <li><strong>Druif:</strong> {product.product_info.druif}</li>}                
                {product.product_info?.vinificatie && <li><strong>Vinificatie:</strong> {product.product_info.vinificatie}</li>}
                {product.product_info?.stijl && <li><strong>Stijl:</strong> {product.product_info.stijl}</li>}
                {product.product_info?.smaak && <li><strong>Smaak:</strong> {product.product_info.smaak}</li>}    
                {product.product_info?.lekker_bij && (
                  <>
                    <strong className="yellow perfectVoorHeader">Lekker bij:</strong>
                    <ul className="perfectVoor">
                      {product.product_info.lekker_bij.split('***').map((item, index) => (
                        <li key={index}>{item.trim()}</li> 
                      ))}
                    </ul>
                  </>
                )}           
    
                
                {(product.product_info?.land || product.product_info?.regio || product.product_info?.streek || product.product_info?.wijnhuis) && <br></br>}
                {product.product_info?.land && <li><strong>Land:</strong> {product.product_info.land}</li>}
                {product.product_info?.regio && <li><strong>Regio:</strong> {product.product_info.regio}</li>}
                {product.product_info?.streek && <li><strong>Streek:</strong> {product.product_info.streek}</li>}
                {product.product_info?.wijnhuis && <li><strong>Wijnhuis:</strong> {product.product_info.wijnhuis}</li>}
              </ul>
            ) : (
              <ul>
                {product.product_info?.inhoud && <li><strong>Inhoud:</strong> {product.product_info.inhoud}cl</li>}
                {product.product_info?.percentage && <li><strong>Alcohol:</strong> {product.product_info.percentage}%</li>}
                {product.product_info?.temperature && <li><strong>Serveertemperatuur:</strong> {product.product_info.temperature}°C</li>}                        
                {(product.product_info?.stijl || product.product_info?.smaak) && <br></br> }
                {product.product_info?.stijl && <li><strong>Stijl:</strong> {product.product_info.stijl}</li>}                            
                {product.product_info?.smaak && <li><strong>Smaak & aromas:</strong> {product.product_info.smaak}</li>}                 
                {product.product_info?.serveren && (
                  <>
                  <br></br>  
                    <strong className="yellow perfectVoorHeader">Perfect voor:</strong>
                    <ul className="perfectVoor">
                      {product.product_info.serveren.split('***').map((item, index) => (
                        <li key={index}>{item.trim()}</li> 
                      ))}
                    </ul>
                  </>
                )}
              </ul>
            )}

            <div className="product-details">
              <div>
                {product.product_info?.sluitzin && <p> {product.product_info.sluitzin}</p>}
              </div>
            </div>
          </div>

          {/* 🔹 Order Section */}
          <div className="order-section">
            <div>
              <label htmlFor="quantity">Aantal:</label>
              <input type="number" id="quantity"
               max={product.product_info?.stock !== null ? product.product_info.stock : 20}
               onChange={handleQuantityChange} value={quantity} name="quantity" />
            </div>
            {cartMessage && (
              <div className={`cart-message ${cartMessageType}`}>
                {cartMessage}
              </div>
            )}
            {(product.product_info?.stock >= 0 && isMobile ) && (
                <p
                  style={{
                    color:
                        product.product_info.stock <= 0 
                        ? "grey"
                        :  product.product_info.stock <= 5 
                        ? "red"
                        : product.product_info.stock <= 10
                        ? "#e57a44ff"
                        : "inherit",
                    fontWeight: "bold",
                    textAlign: "center"
                  }}
                >
                  <strong>Op voorraad:</strong> {product.product_info.stock} stuks
                  {(product.product_info.stock <= 5 && product.product_info.stock > 0 )&& " - Bijna uitverkocht!"}
                  {(product.product_info.stock <= 0 )&& " - Uitverkocht"}
                </p>
              )}      
            <button disabled={product.product_info.stock <= 0}  className="add-to-cart" onClick={addProductToCart}>Toevoegen aan winkelwagen</button>
          </div>       

          <p id="basketStatusMessage">{statusMessage}</p>
          {(product.product_info?.stock >= 0 && !isMobile ) && (
                <p
                  style={{
                    color:
                        product.product_info.stock <= 0 
                        ? "grey"
                        :  product.product_info.stock <= 5 
                        ? "red"
                        : product.product_info.stock <= 10
                        ? "#e57a44ff"
                        : "inherit",
                    fontWeight: "bold",
                    textAlign: "center"
                  }}
                >
                  <strong>Op voorraad:</strong> {product.product_info.stock} stuks
                  {(product.product_info.stock <= 5 && product.product_info.stock > 0 )&& " - Bijna uitverkocht!"}
                  {(product.product_info.stock <= 0 )&& " - Uitverkocht"}
                </p>
              )}      
          <p className="availability"><strong>OOK AF TE HALEN IN ONZE WINKEL</strong></p>

          <div className="nixWrapper"><img src={nix} alt="" /></div>
        </div>
      </div>
    </>
  );
};

export default ProductPage;
