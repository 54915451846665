// src/util/cartContext.js
import React, { createContext, useState, useEffect } from 'react';
import { getWithExpiryAndDecrypt, setWithExpiryAndEncrypt } from './storageFunctions'; // Import your storage functions

export const CartContext = createContext();

const CartProvider = ({ children }) => {
    
  const [cart, setCart] = useState(() => {
    const savedCart = getWithExpiryAndDecrypt('cart'); // Retrieve and decrypt cart data
    return savedCart ? savedCart : [];
  });

  
    const [cartMessage, setCartMessage] = useState("");
    const [cartMessageType, setCartMessageType] = useState("");

  useEffect(() => {
    setWithExpiryAndEncrypt('cart', JSON.stringify(cart), 24 * 60 * 60 * 1000); // Encrypt and store cart data with expiry
  }, [cart]);

  const [totalPrice, setTotalPrice] = useState(0);
  const [shippingCost, setShippingCost] = useState(5.95);

  useEffect(() => {
    const newTotalPrice = cart.reduce((total, item) => {
      return total + item.price * item.quantity;
    }, 0);
    setTotalPrice(newTotalPrice);
    if(newTotalPrice >= 100){
      setShippingCost(0);
    }else{
      setShippingCost(5.95);
    }
  }, [cart]);  

  const addToCart = (product, amount) => {
    setCart(prevCart => {
      const existingProduct = prevCart.find(item => item.name === product.name);
      const maxStock = product.product_info?.stock !== null ? product.product_info.stock : 20; 

      if (existingProduct) {
        const newQuantity = existingProduct.quantity + amount;

        if (newQuantity > maxStock) {
          setCartMessageType("error");
          setCartMessage(`Maximaal aantal (${maxStock}) bereikt.`);
          return prevCart;
        }
        setCartMessageType("success");
        setCartMessage("Product toegevoegd aan winkelwagen!");
        return prevCart.map(item =>
          item.name === product.name
            ? { ...item, quantity: newQuantity }
            : item
        );
      } else {
        const initialQuantity = Math.min(amount, maxStock);
        setCartMessageType("success");
        setCartMessage("Product toegevoegd aan winkelwagen!");
        return [...prevCart, { ...product, quantity: initialQuantity }];
      }
    });

    setTimeout(() => setCartMessage(""), 5000);
  };



  const removeFromCart = (productName) => {
    setCart(prevCart => prevCart.reduce((acc, item) => {
      if (item.name === productName) {
        if (item.quantity > 1) {
          acc.push({ ...item, quantity: item.quantity - 1 });
        }
      } else {
        acc.push(item);
      }
      return acc;
    }, []));
  };

  const removeItem = (productName) => {
    setCart((prevCart) => {
      const updatedCart = prevCart.filter(item => {
        return item.name !== productName;
      });
      return updatedCart;
    });
  };

  const clearCart = () => {
    setCart([]);
  };

  return (
    <CartContext.Provider value={{ cart, addToCart, removeFromCart, clearCart, removeItem, totalPrice, shippingCost, cartMessage, cartMessageType }}>
      {children}
    </CartContext.Provider>
  );
};

export default CartProvider;
