import React, { useState, useEffect } from 'react';
import './cookies.css';

const CookieBanner = () => {
  const [showBanner, setShowBanner] = useState(false);
  const [isAccepted, setIsAccepted] = useState(false);
  const [isRejected, setIsRejected] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem('cookieConsent');
    if (!consent) {
      setShowBanner(true);
    } else {
      if (consent === 'accepted') {
        setIsAccepted(true);
      }
      if (consent === 'declined') {
        setIsRejected(true);
      }
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookieConsent', 'accepted');
    setIsAccepted(true);
    setTimeout(() => {
      setShowBanner(false);
    }, 2500);
  };
  
  const handleReject = () => {
    localStorage.setItem('cookieConsent', 'declined');
    setIsRejected(true);
      setTimeout(() => {
      setShowBanner(false);
    }, 2500);
  };

  return (
    showBanner && (
      <div className="cookie-banner">
        {!isAccepted && !isRejected && (
          <p>
            We gebruiken cookies om je ervaring te verbeteren. Door verder te browsen, ga je akkoord met ons gebruik van cookies.
            <br></br>
            &nbsp;<a href="/privacybeleid">Lees meer</a>.
          </p>
        )}
        {isAccepted && !isRejected && (
          <p>
            Cookies zijn geaccepteerd. Je ervaring wordt verbeterd met cookies.
          </p>
        )}
        {isRejected && !isAccepted && (
          <p>
            We gebruiken geen cookies voor tracking. Je kunt je voorkeuren altijd later aanpassen.
          </p>
        )}
        {!isAccepted && !isRejected && (
          <div>
            <button onClick={handleAccept}>Accepteren</button>
            <button onClick={handleReject}>Weigeren</button>
          </div>
        )}
      </div>
    )
  );
};

export default CookieBanner;
