import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import './styling/retour.css';
import logo from './assets/logo.png';

function RetourBeleid() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [isMobile, setIsMobile] = useState(
    window.matchMedia("(max-width: 1200px)").matches
  );

  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  return (
    <>
      {!isMobile && (
    <div className="desktopNavSpacer"></div>
     )}
     
      <div id="retourBox">

     <div className="retourWrapper">

      <div id="contactTitleWrapper">
          <h1>Retourbeleid – Pulcinos Liquor Co.</h1>
        </div>

        <span>Bij Pulcinos Liquor Co. streven we naar de hoogste kwaliteit en klanttevredenheid. 
            Mocht je om welke reden dan ook niet tevreden zijn met je aankoop, dan kun je deze 
            binnen 20 dagen na ontvangst retourneren voor een volledige terugbetaling, exclusief verzendkosten.</span>

        <div>
            <span><strong>Voorwaarden voor retourneren:</strong></span>
            <ul>
                <li>Het product moet ongeopend, ongebruikt en in de originele verpakking verkeren.</li>
                <li>De retourzending dient binnen 20 dagen na ontvangst bij ons binnen te zijn.</li>
                <li>De verzendkosten voor de retourzending zijn voor rekening van de klant.</li>
                <li>Beschadigde of geopende producten kunnen niet worden geretourneerd.</li>
            </ul>
        </div>

        
        <div>
            <span><strong>Hoe retourneer je een product?</strong></span>
            <ul>
                <li>Stuur een e-mail naar  <a href="mailto:contact@pulcinos.nl">contact@pulcinos.nl</a> met je bestelnummer en reden van retour.</li>
                <li>Je ontvangt instructies voor het retourneren en het retouradres.</li>
                <li>Verpak het product zorgvuldig en stuur het op.</li>
                <li>Zodra wij de retourzending hebben ontvangen en gecontroleerd, wordt het aankoopbedrag binnen [5] werkdagen teruggestort op je rekening.</li>
            </ul>
        </div>

        <div>
        <span>Heb je vragen? Neem gerust contact met ons op via  <a href="mailto:contact@pulcinos.nl">contact@pulcinos.nl</a></span>
        
        <img src={logo} alt=""></img>
        <span className="closingTitle"><strong>Pulcinos Liquor Co. –</strong></span>

        </div>
      
        </div>
      </div>
    </>
  );
}

export default RetourBeleid;
