import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { CartContext } from "../../util/cartContext";
import { AgeVerificationContext } from "../../util/AgeVerificationContext";
import { fetchProductById } from "../../dataSource/ProductSource"; 
import menuLogo from "..//assets/logoTrans.png";
import "./styling/productPage.css";
import "./styling/productPage-s.css";
import "./styling/productPage-m.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import image1 from "../assets/yuzu.jpg";
import image2 from "../assets/yuzu.png";
import arrow from "./assets/arrow-back.svg";
import logo from "../assets/logoWhite.png";
import silver from "./assets/silver.png";
import nix from "./assets/nix.png";

const Yuzu = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [isMobile, setIsMobile] = useState(
    window.matchMedia("(max-width: 1200px)").matches
  );

  const { cart, addToCart, removeFromCart, totalPrice, cartMessage, cartMessageType } =
    useContext(CartContext);

  const [quantity, setQuantity] = useState(1);  
  const { isOver18, setIsOver18 } = useContext(AgeVerificationContext);

  const [product, setProduct] = useState(null);
  
      useEffect(() => {
        window.scrollTo(0, 0);
        const fetchProduct = async () => {
          const data = await fetchProductById(3);
          if (data) setProduct(data);
        };
        fetchProduct();
      }, []);
  

  const addProductToCart = (product, amount) => {
    addToCart(product, amount);
    setQuantity(1);
  };

  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);

  const images = [image1, image2];

  const settingsMain = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    lazyLoad: "ondemand", // Lazy load images on demand
    asNavFor: nav2, // Sync with thumbnail slider
    arrows: false,
  };

  const settingsThumbs = {
    slidesToShow: 2,
    slidesToScroll: 1,
    dots: false,
    centerMode: false,
    focusOnSelect: true,
    asNavFor: nav1, // Sync with main slider
    arrows: false,
    infinite: false,
  };

  const handleQuantityChange = (e) => {
    const value = parseInt(e.target.value, 10);
    if (isNaN(value) || value < 1) {
        setQuantity(1); // Reset to 1 if below minimum
    } else {
        setQuantity(value);
    }
};


  return (
    <>
    {!isOver18 && (
        <div id="ageWrapper">
          <div id="ageBox">

            <div id="ageLogoWrapper">
              <img src={menuLogo} alt=""></img>
            </div>

            <h1>Ben je 18 jaar of ouder?</h1>

            <div id="ageButtons">

              <button className="checkout-btn yes-btn" onClick={() => setIsOver18(true)}>Ja</button>
              <Link
                to="/"
              >
              <button className="checkout-btn">Nee</button>
              </Link>

            </div>
          </div>
        </div>
      )}
      {!isMobile && <div className="desktopNavSpacer"></div>}
      <div className="back">
        <Link
          className="hightlightsLink"
          to="/webshop"
          style={{
            textDecoration: "none",
            display: "flex",
            alignItems: "center",
          }}
        >
          <img src={arrow} alt="back arrow"></img>
          <p>Terug naar Webshop</p>
        </Link>
      </div>
      <div className="product-page">
        <div className="product-image">
          <Slider
            {...settingsMain}
            ref={(slider) => setNav1(slider)}
            className="slider-for"
          >
            {images.map((image, index) => (
              <div key={index}>
                <img
                  src={image}
                  alt={`slide-${index}`}
                  style={{ width: "100%", height: "600px", objectFit: "cover" }}
                />
              </div>
            ))}
          </Slider>

          {/* Thumbnail Slider */}
          <Slider
            {...settingsThumbs}
            ref={(slider) => setNav2(slider)}
            className="slider-nav"
          >
            {images.map((image, index) => (
              <div key={index}>
                <img
                  src={image}
                  alt={`thumbnail-${index}`}
                  style={{
                    width: "100%",
                    height: "100px",
                    objectFit: "cover",
                    cursor: "pointer",
                  }}
                />
              </div>
            ))}
          </Slider>
        </div>
        <div className="product-content">
          <div className="productTitle">
            {isMobile && (
              <div className="logoWrapper">
                <img src={logo} alt="Pulcinos logo"></img>
              </div>
            )}
            {!isMobile && <img src={logo} alt="Pulcinos logo"></img>}
            <h1>Pulcinos Yuzu</h1>
            {isMobile && (
              <p className="description">
                Gemaakt van verse biologische yuzu uit Japan
              </p>
            )}
            <h2>€26,50</h2>
            {!isMobile && (
              <p className="description">
                Gemaakt van verse biologische yuzu uit Japan
              </p>
            )}
          </div>

          <div className="product-description">
            <p>
              Yuzu from the far east is a patient but persistent fruit. It needs
              to grow up to 10 years on the cold mountaintops of Japan. The
              aromatic peel gives a subtle lemon taste with hints of mandarin.
              The careful selection of fruits combined with 100% organic
              products is made into the best balanced citrus liquors with a full
              and rich taste.
            </p>
            <div className="product-details">
              <div>
                <img src={silver} alt=""></img>
              </div>
              <div>
                <p>
                  <strong>Inhoud:</strong> 50cl
                </p>
                <p>
                  <strong>Alcohol:</strong> 28%
                </p>
                <p>
                  <strong>Serveertemperatuur:</strong> -18°C
                </p>
              </div>
            </div>
          </div>

          <div className="order-section">
            <div>
              <label for="quantity">Aantal:</label>
              <input
                type="number"
                id="quantity"
                value={quantity}
                name="quantity"
                onChange={handleQuantityChange}
              />
            </div>
            <button
              className="add-to-cart"
              onClick={() => addProductToCart(product, quantity)}
            >
              Toevoegen aan winkelwagen
            </button>
          </div>

          {cartMessage && (
              <div className={`cart-message ${cartMessageType}`}>
                {cartMessage}
              </div>
            )}

          <p className="availability">
            <strong>OOK AF TE HALEN IN ONZE WINKEL</strong>
          </p>

          <div className="nixWrapper">
            <img src={nix} alt=""></img>
          </div>
        </div>
      </div>
    </>
  );
};

export default Yuzu;
