const BASE_URL = process.env.REACT_APP_BASE_URL;

export async function sendShippingConfirmation(order) {

    console.log("Sending mail for order, ", order);

    try {
        const response = await fetch(`${BASE_URL}/send-shipping-confirmation`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                to_email: order.customer.email_address,
                order_details: order,
                order_id: order.id,
                payment_method: order.payment_method,
            }),
        });

        if (response.ok) {
            return { success: true, message: "Verzendbevestiging verzonden! 📧" };
        } else {
            console.error("Fout bij verzenden van verzendbevestiging:", response);
            return { success: false, message: "Fout bij verzenden van e-mail ❌" };
        }
    } catch (error) {
        console.error("Fout bij verzenden van verzendbevestiging:", error);
        return { success: false, message: "Er is een fout opgetreden bij het verzenden van de e-mail." };
    }
}

export async function sendPickupConfirmation(order) {
    try {
        const response = await fetch(`${BASE_URL}/send-pickup-confirmation`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                to_email: order.customer.email_address,
                order_details: order,
                order_id: order.id,
                payment_method: order.payment_method,
            }),
        });

        if (response.ok) {
            return { success: true, message: "Ophaalbevestiging verzonden! 📧" };
        } else {
            console.error("Fout bij verzenden van ophaalbevestiging:", response);
            return { success: false, message: "Fout bij verzenden van e-mail ❌" };
        }
    } catch (error) {
        console.error("Fout bij verzenden van ophaalbevestiging:", error);
        return { success: false, message: "Er is een fout opgetreden bij het verzenden van de e-mail." };
    }
}
