import React, { useState, useEffect } from "react";
import "./styling/adminSideBar.css";
import { Link } from "react-router-dom";
import { getAllOrders } from "../../dataSource/OrderSource";
import logoWhite from "../../pages/assets/logo.png";

function AdminSideBar({ activePage, setActivePage, sidebarOpen }) {
  const [isMobile, setIsMobile] = useState(
    window.matchMedia("(max-width: 1200px)").matches
  );

  const [orders, setOrders] = useState([]);
  const [newOrdersCount, setNewOrdersCount] = useState(0); 
  const [username, setUsername] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const storedUsername = localStorage.getItem("Username");
    setUsername(storedUsername);

  }, []);

  useEffect(() => {
    async function fetchData() {
      const result = await getAllOrders();

      if(result){
      const sortedOrders = result.sort((a, b) => b.id - a.id);
      setOrders(sortedOrders);

      // Filter orders that are 'Ontvangen' and have 'Completed' payment status
      const received = sortedOrders.filter(
        (order) =>
          order.status === "Ontvangen" && order.payment_status === "Completed"
      );

      setNewOrdersCount(received.length); // Set the count of new orders
    }
    }

    fetchData();
  }, []);

  useEffect(() => {
    console.log("activepage: ", activePage);
  }, [activePage]);

  // Determine button color based on new orders count
  const getButtonClass = () => {
    if (newOrdersCount > 5) {
      return "btn-download red"; // More than 5 orders, red
    } else if (newOrdersCount > 0) {
      return "btn-download orange"; // 1-5 orders, orange
    }
    return "btn-download"; // No new orders, default button
  };

  const filteredLinks = [
    { key: "setmenu-pulcinos", icon: "bi bi-pencil-square", label: "Bewerk Menukaart Pulcinos", menuGroup: "Menukaart" },
    { key: "setmenu-satiata", icon: "bi bi-pencil-square", label: "Bewerk Menukaart Satiata", menuGroup: "Menukaart" },
    { key: "shipping", icon: "bi bi-box2-heart", label: "Bestellingen Verwerken", extraClass: getButtonClass(), count: newOrdersCount, menuGroup: "Webshop" },
    { key: "orders", icon: "bi bi-list-ul", label: "Alle Bestellingen", menuGroup: "Webshop" },
    { key: "products", icon: "bi bi-shop", label: "Beheer Producten", menuGroup: "Webshop" },
    { key: "coupons", icon: "bi bi-ticket-detailed", label: "Coupons", menuGroup: "Webshop" },
    { key: "analytics", icon: "bi bi-bar-chart-fill", label: "Statistieken", menuGroup: "Inzichten" },
    { key: "gebruikers", icon: "bi bi-people-fill", label: "Gebruikers", menuGroup: "Beheer" },
  ].filter(link => link.label.toLowerCase().includes(searchTerm.toLowerCase()));

  const groupedLinks = filteredLinks.reduce((acc, link) => {
    if (!acc[link.menuGroup]) acc[link.menuGroup] = [];
    acc[link.menuGroup].push(link);
    return acc;
  }, {});

  return (
    <>
      <div id="adminSideBarBox" className={isMobile ? (sidebarOpen ? "sidebar-open" : "sidebar-closed") : ""}>
        <img src={logoWhite} alt=""></img>

        <div id="adminSideBarSearch">
        <i className="bi bi-search"></i>
          <input 
            type="text" 
            placeholder="Zoek in menu..." 
            value={searchTerm} 
            onChange={(e) => setSearchTerm(e.target.value)} 
          />
        </div>

        <div id="adminSideBarLinks">
        <ul>
          {Object.entries(groupedLinks).map(([group, links]) => (
            <React.Fragment key={group}>
              <h3>{group}</h3>
              {links.map((link, index) => {
                const liClassName = [
                  link.extraClass || "", 
                  link.key === activePage ? "activeSideBarItem" : ""
                ].join(" ").trim();

                return (
                    <li
                    key={index}
                    className={`${link.extraClass || ""} ${link.key === activePage ? "activeSideBarItem" : ""}`.trim()}
                    onClick={() => {
                      console.log(`Setting Active Page: ${link.key}`);
                      setActivePage(link.key);
                    }}
                  >
                  
                    <i className={link.icon}></i>
                    <span>{link.label}</span>
                    {link.count > 0 && (
                      <span className="order-count">{link.count}</span>
                    )}
                  </li>
                );
              })}
            </React.Fragment>
          ))}
        </ul>
        </div>
      </div>
    </>
  );
}

export default AdminSideBar;
