import { useState } from "react";
import { forgotPassword } from "../dataSource/UserSource";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const [isMobile, setIsMobile] = useState(
      window.matchMedia("(max-width: 1200px)").matches
    );

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await forgotPassword(email);
      setMessage(response.message);
    } catch (error) {
      console.error("Error requesting password reset:", error);
      setMessage("An error occurred. Please try again.");
    }
  };

  return (
    <>
    {!isMobile && (
      <div className="desktopNavSpacer"></div>
       )}
       <div className="login-wrapper">
       <div className="login-box">
      <h2 className='adminHeader'>Wachtwoord vergeten?</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="email"
          placeholder="Enter your email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <button className="btn btn-add-product" type="submit">Reset Wachtwoord</button>
      </form>
      {message && <p>{message}</p>}
    </div>
    </div>
    </>
  );
};

export default ForgotPassword;
