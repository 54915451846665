import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { createCoupon } from "../../dataSource/CouponSource"; // assuming a similar function for coupons
import logo from "../assets/logoWhite.png";
import ConfirmModal from '../../components/ConfirmModal';
import { fetchProducts } from "../../dataSource/ProductSource";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/flatpickr.min.css";

const AddCoupon = ({setActivePage}) => {
  const [modal, setModal] = useState({ show: false, message: "", action: null });
  const [modalAdd, setModalAdd] = useState({ show: false, message: "", action: null });
  const [isMobile, setIsMobile] = useState(window.matchMedia("(max-width: 1200px)").matches);
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    discountType: "percentage", 
    discountValue: "",
    maxDiscountValue: "",
    minimumOrderValue: "",
    startDate: "",
    endDate: "",
    code: "",
    usageLimit: "",
    status: "active", 
    applicableProducts: "ALL_PRODUCTS", 
    active: true,
  });
  const [products, setProducts] = useState([]);
  const [selectAll, setSelectAll] = useState(true);

  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleDateChange = (selectedDates, fieldName) => {
    if (selectedDates.length > 0) {
      // Adjust the date to local time by subtracting the timezone offset
      const selectedDate = selectedDates[0];
      const localDate = new Date(
        selectedDate.getTime() - selectedDate.getTimezoneOffset() * 60000
      );
      // Format the date string as you need, e.g., "YYYY-MM-DD HH:mm:ss"
      const formattedDate = localDate.toISOString().slice(0, 19).replace("T", " ");
      setFormData({ ...formData, [fieldName]: formattedDate });
    }
  };

    useEffect(() => {
      loadProducts();
    }, []);
  
    const loadProducts = async () => {
      const data = await fetchProducts();
      console.log("found products: ", data);
      setProducts(data);
    };

const handleProductSelect = (productId) => {
    let updatedProducts = [...formData.applicableProducts];
    if (updatedProducts.includes(productId)) {
      updatedProducts = updatedProducts.filter(id => id !== productId);
    } else {
      updatedProducts.push(productId);
    }
    setFormData({ ...formData, applicableProducts: updatedProducts });
  };

  const handleSelectAllToggle = () => {
    if (selectAll) {
      setFormData({ ...formData, applicableProducts: [] });
    } else {
      setFormData({ ...formData, applicableProducts: 'ALL_PRODUCTS'});
    }
    setSelectAll(!selectAll);
  };

  // ✅ Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    setModalAdd({
      show: true,
      message: "Weet je zeker dat je deze coupon wilt toevoegen?",
      action: async () => {
        const couponData = {
          code: formData.code,
          description: formData.description,
          discount_type: formData.discountType,
          discount_value: parseFloat(formData.discountValue),
          max_discount_value: parseFloat(formData.maxDiscountValue),
          minimum_order_value: formData.minimumOrderValue ? parseFloat(formData.minimumOrderValue) : null,
          start_date: formData.startDate,
          end_date: formData.endDate,
          usage_limit: formData.usageLimit ? parseInt(formData.usageLimit) : null,
          usage_count: 0, 
          status: formData.status,
          applicable_products: formData.applicableProducts || [],
          active: formData.active,
        };

        await createCoupon(couponData);
        setActivePage("coupons");

        setModalAdd({ show: false, message: "", action: null });
      },
    });
  };

  return (
    <>
      <div className="productOverviewWrapper">
        <div className="container containerProducts">
        
          <h2 className="adminHeader">Nieuwe Coupon Toevoegen</h2>
          <p>Voeg hier een coupon toe, velden met * zijn verplicht</p>

          <form onSubmit={handleSubmit} className="coupon-form lightBorder">
          
          <label><h4 className="adminHeader">* Couponcode:</h4></label>         
            <input
              type="text"
              name="code"
              placeholder="Couponcode"
              value={formData.code}
              onChange={handleChange}
              required
            />

            <label><h4 className="adminHeader">* Beschrijving:</h4></label>
            <textarea
              name="description"
              placeholder="Beschrijving van de coupon"
              value={formData.description}
              onChange={handleChange}
              required
            />

            <label><h4 className="adminHeader">* Korting Type:</h4></label>
            <select
              name="discountType"
              value={formData.discountType}
              onChange={handleChange}
              required
            >
              <option value="percentage">Percentage</option>
              <option value="fixed">Vast bedrag</option>
              <option value="shipping">Gratis Verzending</option>
            </select>

            {formData.discountType !== 'shipping' && (
            <>
            <label><h4 className="adminHeader">* Korting Waarde in % of €:</h4></label>
            <input
              type="number"
              name="discountValue"
              placeholder="Kortingswaarde"
              value={formData.discountValue}
              onChange={handleChange}
              required
            />          
            </>
            )}

            {formData.discountType === 'percentage' && (
            <>
            <label><h4 className="adminHeader">Maximale korting in €: (laat leeg voor onbeperkt)</h4></label>
            <input
              type="number"
              name="maxDiscountValue"
              placeholder="Maximale Kortingswaarde"
              value={formData.maxDiscountValue}
              onChange={handleChange}
            />
            </>
            )}

            <label><h4 className="adminHeader">Minimale Bestelwaarde in €:</h4></label>
            <input
              type="number"
              name="minimumOrderValue"
              placeholder="Minimale bestelwaarde"
              value={formData.minimumOrderValue}
              onChange={handleChange}
            />

            <label><h4 className="adminHeader">* Geldig vanaf:</h4></label>
            <Flatpickr
              value={formData.startDate}
              options={{
                dateFormat: "Y-m-d",
                disableMobile: true, 
              }}
              onChange={(selectedDates) => handleDateChange(selectedDates, "startDate")}
            />

            <label><h4 className="adminHeader">* Geldig tot:</h4></label>
            <Flatpickr
              value={formData.endDate}
              options={{ dateFormat: "Y-m-d",
                disableMobile: true, 
               }}
              onChange={(selectedDates) => handleDateChange(selectedDates, "endDate")}
            />


            <label><h4 className="adminHeader">Gebruikslimiet: (laat leeg voor onbeperkt)</h4></label>
            <input
              type="number"
              name="usageLimit"
              placeholder="Gebruikslimiet"
              value={formData.usageLimit}
              onChange={handleChange}
            />

            <label><h4 className="adminHeader">Status:</h4></label>
            <select
              name="status"
              value={formData.status}
              onChange={handleChange}
            >
              <option value="active">Actief</option>
              <option value="inactive">Inactief</option>
              <option value="expired">Verlopen</option>
              <option value="disabled">Uitgeschakeld</option>
            </select>

            <label><h4 className="adminHeader">* Toepasbare Producten:</h4></label>
            <input
                type="text"
                value={
                  formData.applicableProducts === "ALL_PRODUCTS"
                    ? "Alle Producten"
                    : Array.isArray(formData.applicableProducts)
                    ? formData.applicableProducts.join(", ")
                    : ""
                }
                onFocus={() => setModal({ show: true, message: "Selecteer producten", action: null })}
                readOnly
              />
            <button type="submit" className="btn-save-product">Voeg Coupon Toe</button>
          </form>
        </div>
      </div>

      {/* Confirmation Modal */}
      <ConfirmModal
        show={modalAdd.show}
        message={modalAdd.message}
        onConfirm={modalAdd.action}
        onCancel={() => setModalAdd({ show: false, message: "", action: null })}
      />

      {/* Product Selection Modal */}
      {modal.show && (
        <div className="product-select-modal-wrapper">
        <div className="product-select-modal">
          <div className="modal-content">
            <h3 className="adminHeader">Selecteer Producten</h3>
            <button  onClick={handleSelectAllToggle}>
            <i class="bi bi-check2-square"></i>  {selectAll ? "Deselecteer Alles" : "Selecteer Alles"}
            </button>

            <div className="product-list lightBorder">
              {Object.values(products).map((product) => (
                <div key={product.id} className="product-item">
                  <input
                    type="checkbox"
                    checked={
                      formData.applicableProducts === "ALL_PRODUCTS" ||
                      formData.applicableProducts.includes(product.id)
                    }
                    onChange={() => handleProductSelect(product.id)}
                    disabled={formData.applicableProducts === "ALL_PRODUCTS"} 
                  />
                  <span>{product.name}</span> - <span>{product.price}</span>
                </div>
              ))}
            </div>

            <button onClick={() => setModal({ show: false, message: "", action: null })}><i class="bi bi-x-square"></i></button>
          </div>
        </div>
        </div>
      )}
    </>
  );
};

export default AddCoupon;
