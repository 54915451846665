const BASE_URL = process.env.REACT_APP_BASE_URL;
const maxRetries = 5;
const retryDelay = 1000;

async function fetchWithRetry(url, options = {}, retries = maxRetries) {
  console.log(`fetchWithRetry called with retries: ${retries}`);
  for (let attempt = 1; attempt <= retries; attempt++) {
    try {
      const response = await fetch(url, options);
      if (response.ok) return response; // Success
      console.error(`Attempt ${attempt} failed: ${response.statusText}`);
    } catch (error) {
      console.error(`Attempt ${attempt} failed with error:`, error);
      if (attempt === retries) throw error; // Re-throw if final attempt
    }
    // Wait before next attempt
    await new Promise(resolve => setTimeout(resolve, retryDelay));
  }
}

export async function getAllCoupons() {
  const token = localStorage.getItem("Token");

  try {
    const response = await fetchWithRetry(`${BASE_URL}/coupons`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`, 
      },
    });

    return response.ok ? await response.json() : false;
  } catch (error) {
    console.error("Error fetching coupons:", error);
    return false;
  }
}


  export async function getCouponById(id) {
    try {
      const response = await fetchWithRetry(`${BASE_URL}/coupons/${id}`);
      if (response.ok) {
        const coupon = await response.json();
        return coupon;
      } else {
        console.error('Failed to fetch coupon details');
        return false;
      }
    } catch (error) {
      console.error('Error fetching coupon details:', error);
      return false;
    }
  }

  export async function createCoupon(couponData) {
    const token = localStorage.getItem("Token");
  
    try {
      const response = await fetchWithRetry(`${BASE_URL}/coupons`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`, 
        },
        body: JSON.stringify(couponData),
      });
  
      return response.ok ? await response.json() : false;
    } catch (error) {
      console.error("Error creating coupon:", error);
      return false;
    }
  }
  

  export async function updateCoupon(id, couponData) {
    const token = localStorage.getItem("Token");
  
    try {
      const response = await fetchWithRetry(`${BASE_URL}/coupons/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`, 
        },
        body: JSON.stringify(couponData),
      });
  
      return response.ok ? await response.json() : false;
    } catch (error) {
      console.error("Error updating coupon:", error);
      return false;
    }
  }
  

  
  export async function deleteCoupon(id) {
    const token = localStorage.getItem("Token");
  
    try {
      const response = await fetchWithRetry(`${BASE_URL}/coupons/${id}`, {
        method: "DELETE",
        headers: {
          "Authorization": `Bearer ${token}`, 
        },
      });
  
      return response.ok;
    } catch (error) {
      console.error("Error deleting coupon:", error);
      return false;
    }
  }
  
  
  
  export async function validateCoupon(couponCode) {
    try {
      const response = await fetchWithRetry(`${BASE_URL}/coupons/validate`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ coupon_code: couponCode }),
      }, 2);
  
      if (response.ok) {
        const data = await response.json();
        console.log(data);
        return data; 
      } else {
        const errorData = await response.json();
        throw new Error(errorData.error || "Something went wrong while validating the coupon");
      }
    } catch (error) {
      console.error("Error validating coupon:", error);
      return { error: error.message }; // Handle errors gracefully
    }
  }
  
 
  