const API_BASE_URL = process.env.REACT_APP_BASE_URL;


export const fetchProducts = async () => {
  const response = await fetch(`${API_BASE_URL}/products`);
  return response.json();
};

export const fetchProductById = async (id) => {
  const response = await fetch(`${API_BASE_URL}/product/${id}`);
  return response.json();
};

export const createProduct = async (productData, selectedFiles) => {
  console.log("📤 Creating product...");

  const token = localStorage.getItem("Token");

  try {
    const formData = new FormData();

    // Append product data fields
    Object.keys(productData).forEach((key) => {
      try {
        if (typeof productData[key] === "object") {
          formData.append(key, JSON.stringify(productData[key]));
        } else {
          formData.append(key, productData[key]);
        }
      } catch (err) {
        console.error(`❌ Error processing key "${key}":`, err);
      }
    });

    // Append image files
    selectedFiles.forEach((file) => {
      try {
        formData.append("images", file);
      } catch (err) {
        console.error("❌ Error processing file:", file, err);
      }
    });

    const response = await fetch(`${API_BASE_URL}/product`, {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${token}`,
      },
      body: formData,
    });

    console.log("✅ Response Status:", response.status);
    console.log("✅ Response Headers:", response.headers);

    // ✅ Handle non-OK responses (e.g., 500, 400)
    if (!response.ok) {
      const errorText = await response.text();
      console.error(`❌ API Error (${response.status}):`, errorText);
      throw new Error(`API returned an error (${response.status}): ${errorText}`);
    }

    // ✅ Ensure response is JSON before parsing
    const contentType = response.headers.get("content-type");
    if (contentType && contentType.includes("application/json")) {
      return await response.json();
    } else {
      const text = await response.text(); // Read as plain text
      console.error("❌ Unexpected response format:", text);
      throw new Error("Unexpected response format, expected JSON.");
    }
  } catch (error) {
    console.error("🚨 Fatal error in createProduct:", error);
    throw new Error("Failed to create product. Please try again.");
  }
};


export const updateProduct = async (id, updatedData) => {
  const token = localStorage.getItem("Token");

  const response = await fetch(`${API_BASE_URL}/product/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`, 
    },
    body: JSON.stringify(updatedData),
  });

  return response.ok ? await response.json() : false;
};


export const deleteProduct = async (id) => {
  const token = localStorage.getItem("Token");

  const response = await fetch(`${API_BASE_URL}/product/${id}`, {
    method: "DELETE",
    headers: {
      "Authorization": `Bearer ${token}`,
    },
  });

  return response.ok ? await response.json() : false;
};