import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import logo from "../assets/logoWhite.png";
import nix from "../product pages/assets/nix.png";
import arrow from "../product pages/assets/arrow-back.svg";
import { ClipLoader } from 'react-spinners';

const ProductPreview = () => {
  const [product, setProduct] = useState(null);
  const [productType, setProductType] = useState("");
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [isMobile, setIsMobile] = useState(
    window.matchMedia("(max-width: 1200px)").matches
  );

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.data?.checkReady) {
        window.opener?.postMessage({ ready: true }, "*");
      }
      if (event.data?.productData) {
        setProduct({
          ...event.data.productData,
          selectedFiles: event.data.selectedFiles || [],
        });}
    if (event.data?.productType) {
        setProductType(event.data.productType);
      }
    };

    window.addEventListener("message", handleMessage);
    return () => window.removeEventListener("message", handleMessage);
  }, []);

  useEffect(() => {
    console.log("Product, ", product);
    console.log("ProductyType: ", productType);
  }, [product, productType]);

  const settingsMain = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    lazyLoad: "ondemand",
    asNavFor: nav2,
    arrows: false,
  };

  const settingsThumbs = {
    slidesToShow: 2,
    slidesToScroll: 1,
    dots: false,
    centerMode: false,
    focusOnSelect: true,
    asNavFor: nav1,
    arrows: false,
    infinite: false,
  };

  return (
    <>
      {!isMobile && <div className="desktopNavSpacer"></div>}

      <div className="back">
        <Link
          className="hightlightsLink"
          to="/webshop"
          style={{
            textDecoration: "none",
            display: "flex",
            alignItems: "center",
          }}
        >
          <img src={arrow} alt="Terug" />
          <p>Terug naar Webshop</p>
        </Link>
      </div>

      {product ? (

      <div className="product-page">
        {/* 🔹 Product Image Section */}
        <div className="product-image">
          {product.selectedFiles.length > 0 ? (
            product.selectedFiles.length > 1 ? (
              <>
                <Slider {...settingsMain} ref={(slider) => setNav1(slider)} className="slider-for">
                  {product.selectedFiles.map((file, index) => (
                    <div key={index}>
                      <img
                        src={file.url}
                        alt={`slide-${index}`}
                        style={{ width: "100%", height: "600px", objectFit: "cover" }}
                      />
                    </div>
                  ))}
                </Slider>
                <Slider {...settingsThumbs} ref={(slider) => setNav2(slider)} className="slider-nav">
                  {product.selectedFiles.map((file, index) => (
                    <div key={index}>
                      <img
                        src={file.url}
                        alt={`thumbnail-${index}`}
                        style={{
                          width: "100%",
                          height: "100px",
                          objectFit: "cover",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  ))}
                </Slider>
              </>
            ) : (
              <img src={product.selectedFiles[0].url} alt="Product preview" className="slider-main-image single-image" />
            )
          ) : (
            <p>Geen afbeeldingen beschikbaar</p>
          )}
        </div>

        {/* 🔹 Product Info Section */}
        <div className="product-content">
          <div className="productTitle">
            <img src={logo} alt="Pulcinos logo" />
            <h1>{product.name || "Productnaam"}</h1>
            {product.onder_titel && <p className="description">{product.onder_titel}</p>}
            <h2>€{parseFloat(product.price).toFixed(2) || "0.00"}</h2>
          </div>

          <div className="product-description">
            <p>{product?.beschrijving || "Geen beschrijving beschikbaar"}</p>

            {productType === "Wijn" ? (
              <ul>
                {product?.inhoud && <li><strong>Inhoud:</strong> {product.inhoud}cl</li>}
                {product?.percentage && <li><strong>Alcohol:</strong> {product.percentage}%</li>}
                {product?.temperatuur && <li><strong>Serveertemperatuur:</strong> {product.temperatuur}°C</li>}
                {product?.serveren && <li><strong>Serveren:</strong> {product.serveren}</li>}                

                {(product?.druif || product?.smaak || product?.stijl || product?.vinificatie) && <br />}
                {product?.druif && <li><strong>Druif:</strong> {product.druif}</li>}                
                {product?.vinificatie && <li><strong>Vinificatie:</strong> {product.vinificatie}</li>}
                {product?.stijl && <li><strong>Stijl:</strong> {product.stijl}</li>}
                {product?.smaak && <li><strong>Smaak:</strong> {product.smaak}</li>}    

                {product?.lekker_bij && (
                  <>
                    <strong className="yellow perfectVoorHeader">Lekker bij:</strong>
                    <ul className="perfectVoor">
                      {product.lekker_bij.split("***").map((item, index) => (
                        <li key={index}>{item.trim()}</li> 
                      ))}
                    </ul>
                  </>
                )}

                {(product?.land || product?.regio || product?.streek || product?.wijnhuis) && <br />}
                {product?.land && <li><strong>Land:</strong> {product.land}</li>}
                {product?.regio && <li><strong>Regio:</strong> {product.regio}</li>}
                {product?.streek && <li><strong>Streek:</strong> {product.streek}</li>}
                {product?.wijnhuis && <li><strong>Wijnhuis:</strong> {product.wijnhuis}</li>}
              </ul>
            ) : (
              <ul>
                {product?.inhoud && <li><strong>Inhoud:</strong> {product.inhoud}cl</li>}
                {product?.percentage && <li><strong>Alcohol:</strong> {product.percentage}%</li>}
                {product?.temperatuur && <li><strong>Serveertemperatuur:</strong> {product.temperatuur}°C</li>}                        
                {(product?.stijl || product?.smaak) && <br />}
                {product?.stijl && <li><strong>Stijl:</strong> {product.stijl}</li>}                            
                {product?.smaak && <li><strong>Smaak & aromas:</strong> {product.smaak}</li>}                 
              </ul>
            )}

            <div className="product-details">
              <div>
                {product?.sluitzin && <p> {product.sluitzin}</p>}
              </div>
            </div>
          </div>

          {/* 🔹 Order Section */}
          <div className="order-section">
            <div>
              <label htmlFor="quantity">Aantal:</label>
              <input type="number" id="quantity" value="1" disabled />
            </div>
            <button className="add-to-cart" disabled>Toevoegen aan winkelwagen</button>
          </div>

          <p className="availability"><strong>OOK AF TE HALEN IN ONZE WINKEL</strong></p>

          <div className="nixWrapper"><img src={nix} alt="NIX logo" /></div>
        </div>
      </div>

        ) : (
            <div className="adminProductSplash">
                 <ClipLoader color="#fed126ff" loading={true} size={15} />
                <h3>Wachten op product...</h3>
            </div>
        )}
    </>
  );
};

export default ProductPreview;
