import React, { useState } from 'react';
import './styling/admin.css';
import AdminSideBar from './AdminSideBar';
import AdminHome from './AdminHome';
import SetMenu from './SetMenu';
import Shipping from './AdminShipping';
import Orders from './AdminOrders';
import Products from './AdminProducts';
import Coupons from './AdminCoupons';
import Analytics from './AdminAnalytics';
import AddProduct from './AdminAddProduct';
import EditProduct from './AdminEditProduct';
import AddCoupon from './AdminAddCoupon';
import EditCoupon from './AdminEditCoupon';
import OrderDetails from './AdminOrderDetails';
import { Link } from 'react-router-dom';
import AdminUsers from './AdminUsers';
import AddUser from './AdminAddUser';

function Admin() {
  const [isMobile, setIsMobile] = useState(
    window.matchMedia("(max-width: 1200px)").matches
  );

  const [activePage, setActivePage] = useState("home"); 
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const handlePageChange = (page) => {
    setActivePage(page);
    if (isMobile) setSidebarOpen(false); 
  };

  const renderPage = () => {
    if (activePage.startsWith("edit-product-")) {
      const productId = activePage.split("-").pop();
      return <EditProduct id={productId} setActivePage={setActivePage}/>;
    }
    if (activePage.startsWith("edit-coupon-")) {
      const couponId = activePage.split("-").pop();
      return <EditCoupon id={couponId} setActivePage={setActivePage}/>;
    }
    if (activePage.startsWith("order-details-")) {
      const orderId = activePage.split("-").pop();
      console.log("found order id:", orderId);
      return <OrderDetails orderId={orderId} />;
    }

    switch (activePage) {
      case "setmenu-pulcinos": return <SetMenu restaurant={"pulcinos"}/>;
      case "setmenu-satiata": return <SetMenu restaurant={"satiata"}/>;
      case "shipping": return <Shipping setActivePage={setActivePage} />;
      case "orders": return <Orders setActivePage={setActivePage} />;
      case "products": return <Products setActivePage={setActivePage} />;
      case "add-product": return <AddProduct setActivePage={setActivePage} />;
      case "coupons": return <Coupons setActivePage={setActivePage} />;
      case "add-coupon": return <AddCoupon setActivePage={setActivePage} />;
      case "analytics": return <Analytics />;
      case "gebruikers": return <AdminUsers setActivePage={setActivePage} />;
      case "add-user": return <AddUser setActivePage={setActivePage} />;
      default: return <AdminHome />; 
    }
  };

  return (
    <>
     {!isMobile && (
      <div className="desktopNavSpacer"></div>
       )}
      {isMobile && (
      <div className="adminNavSpacer"></div>
       )}
        {isMobile && (
          <div className="mobile-header">
            <button className="menu-toggle" onClick={() => setSidebarOpen(!sidebarOpen)}>
            <span><i class="bi bi-list"></i></span>
            </button>
            <Link to={`/`}>
            <span className="mobile-page-title"><i class="bi bi-box-arrow-left"></i>Log uit</span>
            </Link>
          </div>
        )}
       <div id='adminBox'
        className={`admin-container ${sidebarOpen ? "overlay-active" : ""}`} 
        onClick={(e) => {
          if (isMobile && sidebarOpen && !e.target.closest(".admin-sidebar")) {
            setSidebarOpen(false);
          }
        }}
      >
        <AdminSideBar 
          activePage={activePage} 
          setActivePage={handlePageChange} 
          sidebarOpen={sidebarOpen}
        />
        <div id='adminSpacer'></div>
        <div id='adminInnerWrapper'>
          {renderPage()} 
        </div>
      </div>
    </>
  );
}

export default Admin;
