import React, { useState, useEffect, useCallback } from 'react';
import './styling/setMenu.css';
import { Link, useParams } from 'react-router-dom';
import PDFList from '../../components/PDFList';
import { getAllPdfsMetadata, getActivePdf } from '../../dataSource/PDFSource';
import UploadPdfComponent from '../../components/UploadPDF';

function SetMenu({restaurant}) {

  const [activeMenu, setActiveMenu] = useState(null);
  const [dataRefresh, setDataRefresh] = useState(false);
  const [pdfUrl, setPdfUrl] = useState(null);

  const [isMobile, setIsMobile] = useState(
    window.matchMedia("(max-width: 1200px)").matches
  );

  // useEffect(() => {

  //   const loadPdf = async () => {
  //     try {
  //       console.log(restaurant);
  //       let pdfModule;
  //       if (restaurant === 'pulcinos') {
  //         pdfModule = await import('../../pdf/latest_menu_pulcinos.pdf');
  //       } else if (restaurant === 'satiata') {
  //         pdfModule = await import('../../pdf/latest_menu_satiata.pdf');
  //       } 
  //       setPdfUrl(pdfModule.default); // pdfModule.default contains the URL to the PDF
  //     } catch (error) {
  //       console.error("Error loading PDF:", error);
  //     }
  //   };

  //   loadPdf();


  // }, [restaurant]);

  useEffect(() => {
    const loadPdf = async () => {
      try {
        console.log("Fetching PDF for restaurant:", restaurant);
  
        // Fetch the active PDF from the database
        const pdfUrl = await getActivePdf(restaurant);
  
        if (pdfUrl) {
          console.log("PDF URL fetched:", pdfUrl);
          setPdfUrl(pdfUrl); // Set the fetched PDF URL
        } else {
          console.error("Failed to fetch active PDF for the restaurant.");
        }
      } catch (error) {
        console.error("Error loading PDF:", error);
      }
    };
  
    loadPdf();
    findActiveMenu();
    setDataRefresh(true);
  }, [restaurant]);
  

  const findActiveMenu = async () => {
    try {
      const menus = await getAllPdfsMetadata(restaurant);
      if (menus) {
        const activeMenu = menus.find(menu => menu.active === 1); // Assuming active is 1 for active status
     
        if (activeMenu) {
          setActiveMenu(activeMenu);          
        } else {
          setActiveMenu(null);
        }
      }
    } catch (error) {
      console.error('Error fetching active menu:', error);
      setActiveMenu(null);
    }}

  useEffect(() => {
    findActiveMenu();
  }, []);

  useEffect(() => {
    if (dataRefresh) {
      findActiveMenu();
      setDataRefresh(false);
    }
  }, [dataRefresh]);

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);

    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
      timeZone: "Europe/Amsterdam",
    };

    const dateFormatter = new Intl.DateTimeFormat("en-GB", options);

    // Format date and time
    const parts = dateFormatter.formatToParts(date);
    const formattedDate = `${parts.find((p) => p.type === "day").value}-${
      parts.find((p) => p.type === "month").value
    }-${parts.find((p) => p.type === "year").value}`;

    return `${formattedDate}`;
  };

  return (

    <>
    <div id='setMenuBox'>   
    <div id='setMenuWrapper'>  
      <h2 className='adminHeader'>Menu Instellen - {(restaurant.charAt(0).toUpperCase() + restaurant.slice(1))}</h2>           
      <div>

      {activeMenu ? (
        <div className='activeMenuInfo'>         
          <div className='setMenuWrapper lightBorder'>          
          <h3 className='adminHeader'>Actief menu</h3>

          <ul>
          <li><p><strong>Naam: </strong>{activeMenu.name}</p></li>
          <li><p><strong>Bestandsnaam: </strong>{activeMenu.filename}</p></li>
          <li><p><strong>Datum: </strong>{formatDate(activeMenu.timestamp)}</p></li>
           </ul>

           <UploadPdfComponent restaurant={restaurant} setDataRefresh={setDataRefresh} />
          </div>

          {pdfUrl && (
            <div className='lightBorder'>
          {isMobile && (<h3 className='currentMenu'>Huidig menu:</h3>)}
          
            <embed 
              key={pdfUrl} // Add key to force re-render only when URL changes
              src={pdfUrl} 
              width="600" 
              height="600" 
              type="application/pdf" 
            />
            </div>
          )}
        </div>
      ) : (
        <div>
        <p>Geen actief menu ingesteld</p>
        <UploadPdfComponent restaurant={restaurant} setDataRefresh={setDataRefresh} />
        </div>
      )}

      </div>

      <PDFList restaurant={restaurant} dataRefresh={dataRefresh} setDataRefresh={setDataRefresh} formatDate={formatDate} />

      </div> 
    </div>
    </>
  );
}

export default SetMenu;
